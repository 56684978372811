import { FC } from 'react'
import { NonUndefined } from 'react-hook-form'

import LoadingButton from '@/components/loading-button'
import { ActionTimer } from '@/hooks/useActionTimer'
import { Logging } from '@/out/logging'

type Props = {
  configuration: NonUndefined<Logging['cloudwatch']>
  timer: ActionTimer
  onRemove: () => void
}

const CloudwatchDetails: FC<Props> = ({ configuration, timer, onRemove }) => {
  return (
    <div>
      <div className="space-y-3 mb-6 pt-2">
        <div className="flex flex-col space-y-1">
          <span className="text-muted-foreground text-sm">Access Key ID</span>
          <span className="font-medium text-sm">{configuration.accessKeyId}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-muted-foreground text-sm">Region</span>
          <span className="font-medium text-sm">{configuration.region}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-muted-foreground text-sm">Log Group</span>
          <span className="font-medium text-sm">{configuration.logGroup}</span>
        </div>
      </div>
      <LoadingButton
        timer={timer}
        variant="secondary"
        size="sm"
        onClick={onRemove}
        className="hover:bg-destructive text-xs hover:text-background"
      >
        Disconnect
      </LoadingButton>
    </div>
  )
}

export default CloudwatchDetails
