import { FC, useCallback, useState } from 'react'
import { toast } from 'sonner'

import LoadingButton from '@/components/loading-button'
import {
  AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
  AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { useAPI } from '@/hooks/useAPI'
import { deleteCustomerLookup } from '@/thunks/customer-lookup/delete'

type Props = {
  onRemove: () => void
}

const RemoveButton: FC<Props> = ({ onRemove }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [remove, { timer }] = useAPI(deleteCustomerLookup)
  const handleRemove = useCallback(async () => {
    await remove({})
    onRemove()
    setIsOpen(false)
    toast.info('Customer lookup settings have been removed')
  }, [onRemove, remove])

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-muted-foreground">
          Clear settings
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. You&apos;ll need to re-renter all the information to restore the settings.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <LoadingButton timer={timer} onClick={handleRemove}>
            Remove settings
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RemoveButton
