import qs from 'qs'
import { FC, ReactNode } from 'react'

import LoadingButton from '@/components/loading-button'
import useActionTimer from '@/hooks/useActionTimer'

type Props = {
  state?: string
  disabled?: boolean
  children: ReactNode
}

const MicrosoftButton: FC<Props> = ({ state, disabled, children }) => {
  const timer = useActionTimer()

  const handleClick = () => {
    timer.start()

    const query: Record<string, string> = {
      client_id: import.meta.env.VITE_AZURE_CLIENT_ID || '',
      redirect_uri: `${window.location.origin}/api/app/oauth/azure/callback`,
      scope: 'openid email profile',
      response_mode: 'query',
      response_type: 'code',
    }

    if (state) {
      query.state = state
    }

    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${qs.stringify(query)}`
  }

  return (
    <LoadingButton
      size="lg"
      variant="outline"
      disabled={disabled}
      timer={timer}
      className="w-full"
      onClick={handleClick}
    >
      {children}
    </LoadingButton>
  )
}

export default MicrosoftButton
