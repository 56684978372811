import { FC, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import { Button } from '@/components/ui/button'
import { Dialog, DialogTrigger } from '@/components/ui/dialog'
import useObject from '@/hooks/useObject'
import TechnicalDetailsDialog from '@/pages/settings/customer-lookup/TechnicalDetailsDialog'
import { retrieveCustomerLookup } from '@/thunks/customer-lookup/retrieve'

import FormCard from './FormCard'
import TestCard from './TestCard'
import useCustomerLookupUpsert from './useCustomerLookupUpsert'

const FormSchema = z.object({
  url: z
    .string()
    .url({
      message: 'URL must be a valid URL.',
    })
    .startsWith('https://', {
      message: 'URL must start with https://.',
    }),
  method: z.enum(['GET', 'POST']),
  headers: z
    .array(
      z
        .object({
          key: z.string().min(1, { message: 'Header name is required' }),
          value: z.string().min(1, { message: 'Value is required' }),
        })
        .required()
    )
    .max(3, { message: 'You can add up to 3 headers' }),
  customerPath: z.string().min(1, { message: 'Customers array path is required' }),
  customerId: z.string().min(1, { message: 'Customer ID path is required' }),
  titleTemplate: z.string().min(1, { message: 'Title template is required' }),
  descriptionTemplate: z.string().min(1, { message: 'Description template is required' }),
})

export type FormData = z.infer<typeof FormSchema>

const CustomerLookup: FC = () => {
  const [lookup, { wasLoaded, set }] = useObject(retrieveCustomerLookup, {})
  const [upsert, { timer }] = useCustomerLookupUpsert()

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  })

  useEffect(() => {
    if (!lookup) return
    form.reset({
      ...lookup,
      method: lookup.method || 'GET',
      headers: lookup.headerKeys.map(header => ({ key: header, value: '' })),
    })
  }, [lookup, form])

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const resp = await upsert(data)
      set(resp)
    },
    [set, upsert]
  )

  const handleRemove = useCallback(async () => {
    form.reset({
      url: '',
      method: 'GET',
      headers: [],
      customerPath: '',
      customerId: '',
      titleTemplate: '',
      descriptionTemplate: '',
    })
  }, [form])

  return (
    <SettingsLayout>
      <Dialog>
        <div className="flex items-center justify-between gap-8">
          <div className="max-w-2xl">
            <Settings.Title title="Customer Lookup"></Settings.Title>
            <Settings.Description>
              This feature queries your API with a phone number to find matching customer records. Agents will select
              from multiple matches, or proceed automatically if only one customer is found. Your API must return a 2XX
              status code and an array of customer objects.
            </Settings.Description>
          </div>
          <DialogTrigger asChild>
            <Button>View Technical Details</Button>
          </DialogTrigger>
        </div>

        {wasLoaded && (
          <div className="grid gap-6 lg:grid-cols-2">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <FormCard timer={timer} onRemove={handleRemove} />
              </form>
              <TestCard />
            </FormProvider>
          </div>
        )}
        <TechnicalDetailsDialog />
      </Dialog>
    </SettingsLayout>
  )
}

export default CustomerLookup
