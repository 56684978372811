import { useCallback, useEffect } from 'react'

import zoomSDK from '@zoom/appssdk'

import { actions } from '@/slices/auth'
import { useAppDispatch } from '@/store'
import configureZoom from '@/util/configure-zoom'

export default function useZoom() {
  const dispatch = useAppDispatch()

  const handleZoomContext = useCallback(async () => {
    try {
      if (!window.isZoom) return

      await configureZoom()

      const { context } = await zoomSDK.getAppContext()

      const { context: ctx } = await zoomSDK.getRunningContext()
      if (ctx !== 'inMainClient') {
        console.log('[JOURNEY] not in main zoom client')
        return
      }

      const response = await fetch('/api/zoom/context', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ context }),
      })

      const { token } = await response.json()
      dispatch(actions.setJWT(token))
    } catch (e) {
      console.log('[JOURNEY] authentication error: ' + (e as Error).message)
    }
  }, [dispatch])

  useEffect(() => {
    handleZoomContext()
  }, [handleZoomContext])

  return window.isZoom
}
