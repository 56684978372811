import { useEffect, useState } from 'react'

type Timer = [boolean, () => void]

const useTimer = (ms: number = 1250): Timer => {
  const [isOn, setIsOn] = useState(false)

  let timeout: number | undefined = undefined
  function startTimer() {
    setIsOn(true)
    timeout = window.setTimeout(() => setIsOn(false), ms)
  }

  useEffect(() => {
    return () => clearTimeout(timeout)
  }, [timeout])

  return [isOn, startTimer]
}

export default useTimer
