import { useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { CustomerLookup } from '@/out/customer-lookup'
import { Request, upsertCustomerLookup } from '@/thunks/customer-lookup/upsert'

export default function useCustomerLookupUpsert() {
  const [upsert, { timer }] = useAPI(upsertCustomerLookup)

  const handleUpsert = useCallback(
    async (req: NonUndefined<Request>): Promise<CustomerLookup> => {
      const [resp] = await upsert(req)

      toast.success('Customer lookup settings have been saved')

      return resp
    },
    [upsert]
  )

  return [handleUpsert, { timer }] as const
}
