import clsx from 'clsx'
import { InfoIcon } from 'lucide-react'
import { FC } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { FreeTrial } from '@/thunks/admins/bootstrap'

interface CreditUsageBarProps {
  config: FreeTrial
}

const CreditUsageBar: FC<CreditUsageBarProps> = ({ config }) => {
  const percent = config.credits / config.freeTier

  return (
    <div>
      <p className="text-sm font-semibold">
        {config.status === 'free-trial' ? 'Free tier' : 'Usage'}
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon className="w-3 h-3 ml-1" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Free credits used</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </p>
      <div className="flex items-center gap-2 mb-1">
        <p
          className={clsx('text-sm', {
            'text-destructive font-medium': percent >= 0.8,
            'text-yellow-600': percent > 0.5 && percent < 0.8,
          })}
        >
          {config.credits} <span className="text-muted-foreground">/ {config.freeTier}</span>
        </p>
      </div>
    </div>
  )
}

export default CreditUsageBar
