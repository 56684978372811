import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { SMSConfiguration } from '@/out/sms'
import { Request, upsertSMS } from '@/thunks/sms/upsert'

export default function useSMSUpsert(onSetup: (config: SMSConfiguration) => void) {
  const [upsert, { timer }] = useAPI(upsertSMS)

  const handleUpsert = useCallback(
    async (req: Request): Promise<SMSConfiguration> => {
      const [sms] = await upsert(req)

      toast.success('Twilio has been configured', {
        description: `Account SID: ${sms.twilio.accountSid}`,
      })

      onSetup(sms)

      return sms
    },
    [upsert, onSetup]
  )

  return [handleUpsert, { timer }] as const
}
