import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

const LayoutMain: FC<Props> = ({ children, className }) => {
  return (
    <main className={`flex-1 bg-background-dim overflow-hidden flex flex-col ${className ?? ''}`}>
      <div className="flex flex-col flex-1 overflow-y-auto">{children}</div>
    </main>
  )
}

export default LayoutMain
