import { BookText, ShieldCheck } from 'lucide-react'
import { FC } from 'react'

import { appIcons } from '@/apps'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'

const appCards = [
  {
    icon: appIcons['passkeys'],
    title: 'Passkey Authentication',
    description: 'Authenticate customers with their smartphone.',
  },
  {
    icon: appIcons['one-time-passcode'],
    title: 'One Time Passcodes',
    description: 'Update customer authentication in a few hours.',
  },
  {
    icon: appIcons['payments'],
    title: 'Credit Card Payments',
    description: 'Accept digital payments in the contact center.',
  },
  {
    icon: appIcons['e-form'],
    title: 'Secure Data Entry',
    description: 'Collect sensitive data without exposing it to agents.',
  },
] as const

const Resources: FC = () => {
  return (
    <div className="px-4 py-12 mx-auto max-w-7xl">
      <header className="mb-12 text-center">
        <h1 className="mb-4 text-4xl font-bold">Sales Collateral & Resources</h1>
        <p className="text-xl text-muted-foreground">
          These demo videos & documents can be shared with customers & partners.
        </p>
      </header>

      <div>
        <div className="grid max-w-4xl gap-8 mx-auto md:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <BookText className="w-6 h-6" />
                Whitepapers
              </CardTitle>
              <CardDescription>Solution documents for common use cases</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="pl-4 space-y-1 text-sm list-disc text-muted-foreground">
                <li className="cursor-pointer hover:text-foreground">
                  Saving minutes per call with Passkey Authentication
                </li>
                <li className="cursor-pointer hover:text-foreground">
                  Reducing agent turnover with digital signatures
                </li>
                <li className="cursor-pointer hover:text-foreground">An easy win: upgrade to One Time Passcodes</li>
                <li className="cursor-pointer hover:text-foreground">Secure data entry: bypassing the agent</li>
              </ul>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <ShieldCheck className="w-6 h-6" />
                Compliance
              </CardTitle>
              <CardDescription>Documents for Security & Compliance</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="pl-4 space-y-1 text-sm list-disc text-muted-foreground">
                <li className="cursor-pointer hover:text-foreground">PCI v4 Attestation of Compliance</li>
                <li className="cursor-pointer hover:text-foreground">SOC2 Packet</li>
                <li className="cursor-pointer hover:text-foreground">Data Privacy & Residency</li>
                <li className="cursor-pointer hover:text-foreground">Architecture Diagram</li>
                <li className="cursor-pointer hover:text-foreground">ADA Compliance</li>
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
      <hr className="my-8" />
      <div className="grid grid-cols-4 gap-8">
        {appCards.map(({ icon: Icon, ...card }) => (
          <Card key={card.title}>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Icon className="w-6 h-6" />
                {card.title}
              </CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </CardHeader>
            <CardContent>
              <iframe
                src="https://player.vimeo.com/video/930753291?h=d70d88cac8"
                width="100%"
                height="100%"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </CardContent>
            <CardFooter>
              <div className="w-full text-xs text-center cursor-pointer hover:text-foreground text-muted-foreground">
                Copy link to video
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Resources
