import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'
import { AppConfig } from '@/thunks/admins/bootstrap'

type Request = Record<never, never>

type FreeTier = {
  credits: number
  units: string
}

export type Response = {
  apps: Record<App, AppConfig>
  freeTiers: Record<App, FreeTier>
}

export const retrieveBilling = asyncThunk<Request, Response>({
  name: 'billing/usage',
  uri: '/billing',
})
