import { FC, ReactElement, ReactNode, useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router'

import SettingsNav, { NavLink, SettingsContainer } from '@/components/settings-nav'
import SettingsTitle from '@/components/settings-title'

type LinkProps = {
  href: string
  children: string
}

type RouteProps = {
  path: string
  Component: FC
}

type Props = {
  children: [
    ReactElement<{ children: ReactElement<LinkProps>[] }>,
    ReactElement<{ children: ReactElement<RouteProps>[] }>,
  ]
}

const Settings: FC<Props> = ({ children }) => {
  const location = useLocation()
  const page = useMemo(() => {
    const len = children[1].props.children[0].props.path.length - 13
    return location.pathname.substring(len)
  }, [location.pathname, children])
  const cut = useMemo(() => children[1].props.children[0].props.path.lastIndexOf('/'), [children])

  return (
    <SettingsContainer>
      <SettingsNav>
        {children[0].props.children.map(link => (
          <NavLink key={link.props.href} isActive={!!page && link.props.href.endsWith(page)} to={link.props.href}>
            {link.props.children}
          </NavLink>
        ))}
      </SettingsNav>

      <div className="flex-1 overflow-y-auto">
        <div className="px-4 md:px-8 py-6">
          <Routes>
            {children[1].props.children.map(route => (
              <Route key={route.props.path} path={route.props.path.substring(cut)} Component={route.props.Component} />
            ))}
            <Route path="/" element={<Navigate to={children[1].props.children[0].props.path} replace />} />
          </Routes>
        </div>
      </div>
    </SettingsContainer>
  )
}

const SettingsDescription: FC<{ children: ReactNode }> = ({ children }) => (
  <p className="mb-2 max-w-4xl font-light text-muted-foreground text-sm leading-loose">{children}</p>
)

Object.assign(Settings, { Title: SettingsTitle, Description: SettingsDescription })

export default Settings as FC<Props> & { Title: typeof SettingsTitle; Description: typeof SettingsDescription }
