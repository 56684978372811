import './util/sentry'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router'
import { Toaster } from 'sonner'

import { store } from '@/store'
import { createRoot } from 'react-dom/client'

import App from './App.tsx'
import './index.css'

window.isZoom = window.navigator.userAgent.includes('ZoomApps')
window.isStandalone = !window.isZoom

function applyTheme() {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}
window.addEventListener('DOMContentLoaded', applyTheme)
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', applyTheme)

createRoot(document.getElementById('root')!).render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    <Toaster richColors />
  </>
)
