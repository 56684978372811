import { useCallback } from 'react'
import { FieldValues, UseFormSetError } from 'react-hook-form'

import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from '@/hooks/useActionTimer'
import { APIError, isErrorResponse } from '@/lib/asyncThunk'
import { useAppDispatch } from '@/store'
import { setServerErrors } from '@/util/errors'

type ErrorCallback<T> = (resp: APIError<T>) => void

// export type Headers = Record<string, string> | undefined

export type APIResponse<Resp, Headers> = {
  headers: Headers
  data: Resp
}

export type APIThunk<Request, Response, Headers = undefined> = AsyncThunk<
  APIResponse<Response, Headers>,
  Request,
  { rejectValue: APIError<Request> }
>

type Options<T> = {
  setError?: UseFormSetError<Record<string, string>>
  onError?: ErrorCallback<T>
}

export function useAPI<
  Request extends FieldValues,
  Response,
  Headers extends Record<string, string> | undefined = undefined,
>(thunk: APIThunk<Request, Response, Headers>, options?: Options<Request>) {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const execute = useCallback(
    async (req: Request): Promise<[Response, Headers]> => {
      try {
        timer.start()

        const resp = await dispatch(thunk(req)).then(unwrapResult)

        timer.succeeded()

        return [resp.data, resp.headers]
      } catch (err) {
        if (isErrorResponse<Request>(err)) {
          if (options?.setError && err.errors) {
            const errors = err.errors as Record<keyof Request, string>
            setServerErrors<Request>(errors, options.setError)
          }
          if (options?.onError) {
            options.onError(err)
          }
        }

        timer.failed()
        throw err
      }
    },
    [thunk, timer, options, dispatch]
  )

  return [execute, { timer }] as const
}
