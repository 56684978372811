import r from 'routes'

import { App } from '@/apps'

type Navigation = {
  title: string
  path: string
}

export const appNavigation: Record<App, Navigation[]> = {
  'one-time-passcode': [
    {
      title: 'Dashboard',
      path: r.apps['one-time-passcode'].root,
    },
    {
      title: 'Authentications',
      path: r.apps['one-time-passcode'].authentications,
    },
    {
      title: 'Configuration',
      path: r.apps['one-time-passcode'].settings.root,
    },
  ],
  'document-signature': [
    {
      title: 'Dashboard',
      path: r.apps['document-signature'].root,
    },
    {
      title: 'Signatures',
      path: r.apps['document-signature'].signatures,
    },
    {
      title: 'Documents',
      path: r.apps['document-signature'].documents.root,
    },
    {
      title: 'Configuration',
      path: r.apps['document-signature'].settings.root,
    },
  ],
  'e-form': [
    {
      title: 'Dashboard',
      path: r.apps['e-form'].root,
    },
    {
      title: 'Submissions',
      path: r.apps['e-form'].submissions,
    },
    {
      title: 'Forms',
      path: r.apps['e-form'].forms.root,
    },
    {
      title: 'Configuration',
      path: r.apps['e-form'].settings.root,
    },
  ],
  'file-upload': [
    {
      title: 'Dashboard',
      path: r.apps['file-upload'].root,
    },
    {
      title: 'File Uploads',
      path: r.apps['file-upload'].uploads,
    },
    {
      title: 'Configuration',
      path: r.apps['file-upload'].settings.root,
    },
  ],
  passkeys: [
    {
      title: 'Dashboard',
      path: r.apps.passkeys.root,
    },
    {
      title: 'Activity',
      path: r.apps.passkeys.activity,
    },
    {
      title: 'Enrollments',
      path: r.apps.passkeys.enrollments,
    },
    {
      title: 'Configuration',
      path: r.apps.passkeys.settings.root,
    },
  ],
  payments: [
    {
      title: 'Dashboard',
      path: r.apps.payments.root,
    },
    {
      title: 'Payments',
      path: r.apps.payments.payments,
    },
    {
      title: 'Products',
      path: r.apps.payments.products,
    },
    {
      title: 'Configuration',
      path: r.apps.payments.settings.root,
    },
  ],
}
