import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'

type Request = Record<never, never>

type Response = {
  global: DayCount[]
  apps: Record<App, DayCount[]>
  paidAccounts: DayCount[]
}

type DayCount = {
  date: string
  count: number
}

export const statisticsSummary = asyncThunk<Request, Response>({
  name: 'watch-tower/statistics/summary',
  uri: () => `/watch-tower/statistics/summary`,
})
