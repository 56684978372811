import asyncThunk from '@/lib/asyncThunk'

type Request = {
  lastKey?: string
  timezone: string
}

export const exportAuditLog = asyncThunk<Request, string>({
  name: 'audit-log/export',
  uri: '/audit-log/export',
})
