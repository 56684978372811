import { useCallback } from 'react'

export default function useDownload() {
  return useCallback((type: 'csv', filename: string, content: string) => {
    if (type === 'csv') {
      downloadCSV(filename, content)
    } else {
      throw new Error(`Unsupported download type: ${type}`)
    }
  }, [])
}

function downloadCSV(filename: string, content: string) {
  downloadFile(filename, `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`)
}

function downloadFile(filename: string, href: string) {
  const a = document.createElement('a')

  a.textContent = 'download'
  a.download = filename
  a.href = href

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  })
  a.dispatchEvent(clickEvent)
}
