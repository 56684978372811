import asyncThunk from '@/lib/asyncThunk'
import { AuditLogEvent } from '@/out/audit-log-event'

type Request = {
  lastKey?: string
}

type Headers = {
  'x-last-key': string
  'x-count': string
}

export const auditLog = asyncThunk<Request, AuditLogEvent[], Headers>({
  name: 'audit-log/list',
  uri: '/audit-log',
})
