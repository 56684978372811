import asyncThunk from '@/lib/asyncThunk'
import { AWSRegion } from '@/lib/awsRegions'
import { Logging } from '@/out/logging'

export type LoggingCredentials = {
  destination: 'cloudwatch' | 'datadog' | 'sumologic' | 'sftp'

  cloudwatch?: {
    accessKeyId: string
    secretAccessKey: string
    region: AWSRegion
    logGroup: string
  }

  datadog?: {
    apiKey: string
    site: string
  }

  sumologic?: {
    collectorUrl: string
  }

  sftp?: {
    hostname: string
    port: number
    username: string
    password: string
  }
}

export const upsertLogging = asyncThunk<LoggingCredentials, Logging>({
  name: 'logging/upsert',
  method: 'POST',
  uri: '/logging',
})
