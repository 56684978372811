import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { Branding } from '@/out/branding'
import { Request, updateBranding } from '@/thunks/branding/update'

type FormData = {
  name: string
  logo: string | undefined
  color: string
}

export default function useUpdateBranding() {
  const form = useForm<FormData>({
    defaultValues: {
      name: '',
      color: '',
    },
  })
  const [update, { timer }] = useAPI(updateBranding)

  const handleUpdate = useCallback(
    async (req: Request): Promise<Branding> => {
      const [branding] = await update({
        ...req,
        logo: req.logo ? btoa(req.logo) : undefined,
      })

      toast.success('Branding has been updated')

      form.setValue('name', branding.name)
      form.setValue('logo', branding.logo)
      form.setValue('color', branding.color)

      return branding
    },
    [form, update]
  )

  return [handleUpdate, { form, timer }] as const
}
