import { UserPlus } from 'lucide-react'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Label } from 'recharts'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import LoadingButton from '@/components/loading-button'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Invitation } from '@/out/invitation'
import useSendInvitation from '@/pages/settings/admin-management/useSendInvitation'

const schema = z
  .object({
    email: z.string().email(),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = {
  onCreate: (invitation: Invitation) => void
}

const AddAdminDialog: FC<Props> = ({ onCreate }) => {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  })

  const [sendInvitation, { timer }] = useSendInvitation(i => {
    onCreate(i)
    setIsOpen(false)
    form.reset()
  })

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <UserPlus className="w-4 h-4 mr-2" />
          Add an Admin
        </Button>
      </DialogTrigger>
      <DialogContent>
        <form onSubmit={form.handleSubmit(sendInvitation)} className="space-y-4">
          <DialogHeader>
            <DialogTitle>Add New Admin</DialogTitle>
          </DialogHeader>
          <div className="space-y-2">
            <Label>Email</Label>
            <Input {...form.register('email')} autoComplete="one-time-code" placeholder="Enter admin email" />
            {form.formState.errors.email && (
              <p className="text-sm text-red-500">{form.formState.errors.email.message}</p>
            )}
          </div>
          <LoadingButton timer={timer} type="submit" className="w-full">
            Add Admin
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddAdminDialog
