import { FC } from 'react'
import { NonUndefined } from 'react-hook-form'

import LoadingButton from '@/components/loading-button'
import { ActionTimer } from '@/hooks/useActionTimer'
import { Logging } from '@/out/logging'

type Props = {
  configuration: NonUndefined<Logging['datadog']>
  timer: ActionTimer
  onRemove: () => void
}

const DatadogDetails: FC<Props> = ({ configuration, timer, onRemove }) => {
  return (
    <div>
      <div className="pt-2 mb-6 space-y-3">
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">API Key</span>
          <span className="text-sm font-medium">{configuration.apiKey}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Site</span>
          <span className="text-sm font-medium">{configuration.site}</span>
        </div>
      </div>
      <LoadingButton
        timer={timer}
        variant="secondary"
        size="sm"
        onClick={onRemove}
        className="text-xs hover:bg-destructive hover:text-background"
      >
        Disconnect
      </LoadingButton>
    </div>
  )
}

export default DatadogDetails
