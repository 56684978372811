import { FC } from 'react'

import AzureLogo from '@/assets/logos/azure.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { SSO } from '@/out/single-sign-on'
import { SSOProps } from '@/pages/settings/single-sign-on'
import AzureDetails from '@/pages/settings/single-sign-on/azure/details'
import AzureForm from '@/pages/settings/single-sign-on/azure/form'

type Props = SSOProps<'azure'> & {
  configuration: SSO['azure']
}

const AzureAD: FC<Props> = ({ configuration, setupTimer, onSetup, removeTimer, onRemove }) => {
  return (
    <SettingsCard<'sso'>
      title="Azure"
      description="Set up Azure to be the identity provider for single sign-on."
      icon={<AzureLogo className="text-[#252F3E] dark:text-white w-6 h-6" />}
      configuration={configuration}
      onRemove={() => onRemove('azure')}
      removeTimer={removeTimer}
    >
      {configuration ? (
        <AzureDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('azure')} />
      ) : (
        <AzureForm timer={setupTimer} onSetup={config => onSetup('azure', config)} />
      )}
    </SettingsCard>
  )
}

export default AzureAD
