import {
  CreditCard, Fingerprint, FormInput, LucideIcon, Signature, SquareAsterisk,
} from 'lucide-react'

export type App = 'document-signature' | 'file-upload' | 'one-time-passcode' | 'passkeys' | 'payments' | 'e-form'

export type Apps = {
  id: App
  title: string
  icon: LucideIcon
  color: keyof typeof appColors
}[]

const apps: Apps = [
  {
    id: 'passkeys',
    title: 'Passkey Authentication',
    icon: Fingerprint,
    color: 'blue',
  },
  {
    id: 'one-time-passcode',
    title: 'One Time Passcode',
    icon: SquareAsterisk,
    color: 'green',
  },
  {
    id: 'document-signature',
    title: 'Document Signature',
    icon: Signature,
    color: 'blue',
  },
  {
    id: 'e-form',
    title: 'Secure eForm',
    icon: FormInput,
    color: 'rose',
  },
  {
    id: 'payments',
    title: 'Payments',
    icon: CreditCard,
    color: 'green',
  },
]

export const appNames = apps.reduce((acc, app) => ({ ...acc, [app.id]: app.title }), {} as Record<App, string>)
export const appIcons = apps.reduce((acc, app) => ({ ...acc, [app.id]: app.icon }), {} as Record<App, LucideIcon>)

export default apps

export const appColors = {
  blue: 'text-blue-600',
  green: 'text-green-600',
  orange: 'text-orange-600',
  pink: 'text-pink-600',
  purple: 'text-purple-600',
  rose: 'text-rose-600',
}
