import QueryString from 'qs'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { APIResponse, APIThunk } from '@/hooks/useAPI'
import { AppState } from '@/store'

export type Input<Request> = {
  name: string
  uri: ((req: Request) => string) | string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: (req: Request) => Partial<Request>
}

type ErrorResponse<T> = {
  error: string
  errors?: { [P in keyof T]: string }
  code?: string
}

type StatusCode = { statusCode: number }

export type APIError<T> = ErrorResponse<T> & StatusCode

export function isErrorResponse<T>(resp: unknown): resp is APIError<T> {
  return (resp as APIError<T>).error !== undefined
}

export default function asyncThunk<Request, Reply, Headers extends Record<string, string> | undefined = undefined>({
  name,
  method = 'GET',
  uri,
  body,
}: Input<Request>): APIThunk<Request, Reply, Headers> {
  return createAsyncThunk<APIResponse<Reply, Headers>, Request, { rejectValue: APIError<Request> }>(
    name,
    async (req, api) => {
      const headers = new Headers()

      const { auth } = api.getState() as AppState
      if (auth) {
        headers.append('Authorization', `Bearer ${auth.token}`)
      }
      if (method !== 'GET') {
        headers.append('Content-Type', 'application/json')
      }
      headers.append('X-TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)

      const opts: RequestInit = {
        method,
        headers,
      }

      let payload = typeof body === 'function' ? body(req) : body
      if (!payload) payload = req

      let appUri = typeof uri === 'function' ? uri(req) : uri
      if (payload) {
        if (method === 'GET') {
          const query = QueryString.stringify(payload, { arrayFormat: 'brackets' })
          if (query.length > 0) {
            appUri = `${appUri}?${query}`
          }
        } else {
          opts.body = JSON.stringify(payload)
        }
      }

      const response = await fetch(`/api${appUri}`, opts)

      if (response.status >= 400 && response.status < 500) {
        const resp = await response.json()
        return api.rejectWithValue({
          ...resp,
          statusCode: response.status,
        })
      }

      const expectedStatus = method === 'POST' ? 201 : 200
      if (response.status !== expectedStatus) {
        return api.rejectWithValue({
          statusCode: response.status,
          error: `unexpected response status: ${response.status}`,
        })
      }

      const resp = await response.json()

      const responseHeaders: Record<string, string> = {}
      for (const [key, value] of response.headers) {
        responseHeaders[key] = value
      }

      const res: APIResponse<Reply, Headers> = {
        headers: responseHeaders as Headers,
        data: resp,
      }

      return res
    }
  )
}
