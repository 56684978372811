import clsx from 'clsx'
import { Copy } from 'lucide-react'
import { FC, ReactNode, useState } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { delay } from '@/hooks/useActionTimer'
import useTimer from '@/hooks/useTimer'

type Props = {
  text: string
  children?: ReactNode
}

const CopyButton: FC<Props> = ({ text, children }) => {
  const [wasCopied, start] = useTimer()
  const [isOpen, setIsOpen] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text)
      start()
      setTimeout(() => {
        setIsOpen(false)
      }, delay - 300)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <TooltipProvider>
      <Tooltip open={wasCopied} delayDuration={0}>
        <TooltipTrigger asChild className="group" onClick={handleCopy}>
          {children ?? (
            <Copy className="absolute bottom-0 right-0 w-4 h-4 translate-x-6 cursor-pointer top-0.5 text-muted-foreground hover:text-foreground" />
          )}
        </TooltipTrigger>
        <TooltipContent
          className={clsx({
            'text-white bg-green-600': wasCopied,
          })}
        >
          <p>Copied!</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip open={isOpen} onOpenChange={setIsOpen} delayDuration={0}>
        <TooltipTrigger asChild onClick={handleCopy}>
          {children}
        </TooltipTrigger>
        <TooltipContent>
          <p>Click to copy</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CopyButton
