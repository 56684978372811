import { FC } from 'react'

const CSV: FC = () => {
  // const [timeframe, setTimeframe] = useState<TimeframeValue>('30-days')

  return null

  // return (
  //   <Card className="max-w-lg">
  //     <CardHeader>
  //       <CardTitle>CSV</CardTitle>
  //       <CardDescription>
  //         Download logs as CSV files. Process, search, and analyze your logs in your own platform.
  //       </CardDescription>
  //     </CardHeader>
  //     <CardContent>
  //       <div className="flex items-center space-x-4">
  //         <Timeframe value={timeframe} exclude={['this-quarter', 'last-quarter']} onChange={setTimeframe} />
  //         <Button variant="outline" onClick={() => {}}>
  //           Download
  //         </Button>
  //       </div>
  //     </CardContent>
  //   </Card>
  // )
}

export default CSV
