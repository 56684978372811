import clsx from 'clsx'
import { FC, useState } from 'react'
import { useParams } from 'react-router'

import apps, { appColors } from '@/apps'
import Chart from '@/components/chart'
import { Button } from '@/components/ui/button'

import useMetrics from './useMetrics'

type Params = {
  id: string
}

const WatchTowerAccountStatistics: FC = () => {
  const { id } = useParams<Params>()
  const { global, apps: appStats } = useMetrics(id!)
  const [viewApps, setViewApps] = useState(false)

  if (!global || !appStats) return null

  return (
    <div className="grid flex-1 w-full grid-cols-1 gap-8 sm:grid-cols-3 max-w-7xl">
      <Chart title="Tasks Completed" metrics={[global]} className="flex-1 w-full sm:col-span-3 sm:w-full" />

      {viewApps ? (
        <>
          {apps.map(app => (
            <Chart
              key={app.id}
              slim
              title={
                <span className="flex items-center gap-2">
                  <app.icon className={clsx('w-8 h-8 mr-2', appColors[app.color])} />
                  {app.title}
                </span>
              }
              metrics={[appStats[app.id]]}
              className="flex-1 w-full sm:w-full"
            />
          ))}
        </>
      ) : (
        <div className="flex items-center justify-start -my-8">
          <Button variant="ghost" className="text-muted-foreground/70" onClick={() => setViewApps(true)}>
            view all app statistics
          </Button>
        </div>
      )}
    </div>
  )
}

export default WatchTowerAccountStatistics
