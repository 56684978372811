import asyncThunk from '@/lib/asyncThunk'

type Request = {
  code: string
  state: string
}

type Response = {
  token: string
}

export const ssoCallback = asyncThunk<Request, Response>({
  name: 'sso/oauth/callback',
  method: 'POST',
  uri: '/sso/oauth/callback',
})
