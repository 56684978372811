import { Cable } from 'lucide-react'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { FormProps } from '@/pages/settings/single-sign-on'

const schema = z
  .object({
    discoveryUrl: z.string().url({ message: 'Discovery URL is required' }),
    domain: z.string().min(1, { message: 'Domain is required' }),
    clientId: z.string().min(1, { message: 'Client ID is required' }),
    clientSecret: z.string().min(1, { message: 'Client Secret is required' }),
    adminGroupId: z.string(),
    agentGroupId: z.string(),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = FormProps<'azure'>

const AzureForm: FC<Props> = ({ timer, onSetup }) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      discoveryUrl: '',
      domain: '',
      clientId: '',
      clientSecret: '',
      adminGroupId: '',
      agentGroupId: '',
    },
    mode: 'onChange',
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSetup)} className="mt-6 space-y-4">
        <Field<FormData>
          label="OpenID Configuration URL"
          name="discoveryUrl"
          placeholder="https://login.microsoftonline.com/00000000-0000-0000-0000-00000000/v2.0/.well-known/openid-configuration"
          hint="Overview ➔ Endpoints ➔ OpenID Connect metadata document"
        />

        <Field<FormData>
          label="Email domain"
          name="domain"
          hint="If your company email addresses are name@example.com, this value should be example.com"
        />

        <Field<FormData>
          label="Client ID"
          name="clientId"
          placeholder="Enter your client ID"
          hint="Overview ➔ Application (client) ID"
        />

        <Field<FormData>
          label="Client Secret"
          name="clientSecret"
          placeholder="Enter your client secret"
          hint="Certificates & secrets ➔ New client secret -> Value"
        />

        <Field<FormData>
          label="Admin Group ID"
          name="adminGroupId"
          placeholder="Users in this group will have access to the admin dashboard"
          hint="Groups ➔ All Groups -> Object Id"
        />

        <Field<FormData>
          label="Agent Group ID"
          name="agentGroupId"
          placeholder="Users in this group will have access to the agent application"
          hint="Groups ➔ All Groups -> Object Id"
        />

        <div>
          <label className="text-sm">Configuration in the Azure App Registration</label>
          <p className="mt-1 text-xs text-muted-foreground">
            Authentication ➔ Add a platform ➔ Web ➔ Redirect URI: <br />
            <code>https://{import.meta.env.VITE_AGENT_DOMAIN}/oauth/callback</code>
          </p>
        </div>

        <LoadingButton timer={timer} type="submit" className="w-full">
          <Cable className="w-4 h-4 mr-2" />
          Connect Azure SSO
        </LoadingButton>
      </form>
    </FormProvider>
  )
}

export default AzureForm
