import { useCallback, useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import { APIThunk, useAPI } from './useAPI'

function useObject<
  Model extends FieldValues,
  Req extends FieldValues,
  Headers extends Record<string, string> | undefined = undefined,
>(thunk: APIThunk<Req, Model, Headers>, req: Req) {
  const [retrieve, { timer }] = useAPI<Req, Model, Headers>(thunk)

  const [headers, setHeaders] = useState<Headers>()
  const [object, set] = useState<Model>()
  const [wasLoaded, setWasLoaded] = useState(false)

  const [memoizedReq, setMemoizedReq] = useState<Req>(req)
  useEffect(() => {
    setMemoizedReq(memoizedReq => {
      if (JSON.stringify(memoizedReq) === JSON.stringify(req)) return memoizedReq
      return req
    })
  }, [req])

  const reload = useCallback(async () => {
    try {
      setWasLoaded(false)
      const [data, headers] = await retrieve(memoizedReq)
      setHeaders(headers)
      set(data)
    } catch (err) {
      console.error(err)
    }
    setWasLoaded(true)
  }, [retrieve, memoizedReq])

  useEffect(() => {
    reload()
  }, [reload])

  const patch = useCallback(
    (data: Partial<Model>) => {
      if (!object) return

      set({
        ...object,
        ...data,
      })
    },
    [object, set]
  )

  const clear = useCallback(() => {
    set(undefined)
  }, [set])

  return [object, { wasLoaded, headers, set, patch, clear, timer, reload }] as const
}

export default useObject
