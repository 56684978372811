import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'
import { AppStatus } from '@/thunks/admins/bootstrap'
import { Admin } from '@/watch-tower/out/admin'

type Request = {
  id: string
}

export type Response = {
  account: {
    id: string
    name: string
  }
  branding: {
    logo?: string
    domain?: string
  }
  apps: Record<
    App,
    {
      id: App
      status: AppStatus
    }
  >
  admins: Admin[]
}

export const retrieveAccount = asyncThunk<Request, Response>({
  name: 'watch-tower/accounts/retrieve',
  uri: ({ id }) => `/watch-tower/accounts/${id}`,
})
