import { FC } from 'react'

import { Button } from '@/components/ui/button'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'

const example = {
  data: {
    customers: [
      {
        id: 'alice-johnson',
        name: 'Alice Johnson',
        address: {
          street: 'Osage Dr',
          zipCode: '80303',
        },
      },
      {
        id: 'bob-smith',
        name: 'Bob Smith',
        address: {
          street: 'Pickle Lane',
          zipCode: '38190',
        },
      },
      {
        id: 'charlie-brown',
        name: 'Charlie Brown',
        address: {
          street: 'Pine St',
          zipCode: '48181',
        },
      },
    ],
  },
}

const customers = [
  { id: 'alice-johnson', title: 'Johnson, Alice', description: 'Osage Dr, 80303' },
  { id: 'bob-smith', title: 'Smith, Bob', description: 'Pickle Lane, 38190' },
  { id: 'charlie-brown', title: 'Brown, Charlie', description: 'Pine St, 48181' },
]

const TechnicalDetailsDialog: FC = () => {
  return (
    <DialogContent className="max-w-4xl">
      <DialogHeader className="space-y-1">
        <DialogTitle>Technical Details</DialogTitle>
        <DialogDescription className="text-sm">Example implementation guide</DialogDescription>
      </DialogHeader>

      <div className="flex gap-3">
        <Card className="flex-1">
          <CardHeader className="pb-2">
            <CardTitle className="text-sm">API Implementation</CardTitle>
          </CardHeader>
          <CardContent className="space-y-3 text-xs">
            <pre className="bg-muted/30 p-2 rounded-md font-mono">
              GET https://company.com/path/to/api?phoneNumber=13035551234
            </pre>
            <div className="relative">
              <div className="top-0 right-0 left-0 z-10 absolute bg-gradient-to-b from-background to-transparent h-4" />
              <div className="right-0 bottom-0 left-0 z-10 absolute bg-gradient-to-t from-background to-transparent h-4" />
              <pre
                className={cn(
                  'bg-muted/30 p-2 rounded-md text-[10px] overflow-auto max-h-[200px]',
                  'scrollbar-thin scrollbar-thumb-muted-foreground/20 scrollbar-track-transparent'
                )}
              >
                {JSON.stringify(example, null, 2)}
              </pre>
            </div>
            <div>
              <Label className="font-medium text-xs">Configuration Settings</Label>
              <ul className="space-y-1 mt-1">
                {[
                  { label: 'Customer Path', value: 'data.customers' },
                  { label: 'Customer ID', value: 'id' },
                  { label: 'Title', value: '{lastName}, {firstName}' },
                  { label: 'Description', value: '{address.street}, {address.zipCode}' },
                ].map(item => (
                  <li key={item.label} className="flex items-center gap-1">
                    <span className="font-medium">{item.label}:</span>
                    <code className="bg-muted/30 px-1 py-0.5 rounded">{item.value}</code>
                  </li>
                ))}
              </ul>
            </div>
          </CardContent>
        </Card>

        <Card className="flex-1">
          <CardHeader className="pb-2">
            <CardTitle className="text-sm">Agent Preview</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-1">
              {customers.map(customer => (
                <div
                  key={customer.id}
                  className="flex justify-between items-center hover:bg-accent p-2 rounded-md cursor-pointer"
                >
                  <div>
                    <p className="font-medium text-xs">{customer.title}</p>
                    <p className="text-muted-foreground text-xs">{customer.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      <DialogFooter>
        <DialogClose asChild>
          <Button>Close</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}

export default TechnicalDetailsDialog
