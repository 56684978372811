import asyncThunk from '@/lib/asyncThunk'
import { SSO } from '@/out/single-sign-on'

export type SSOCredentials = {
  provider: 'azure' | 'okta'

  azure?: {
    discoveryUrl: string
    domain: string
    clientId: string
    clientSecret: string
    adminGroupId: string
    agentGroupId: string
  }

  okta?: {
    tenant: string
    domain: string
    clientId: string
    clientSecret: string
    adminGroupId: string
    agentGroupId: string
  }
}

export const upsertSSO = asyncThunk<SSOCredentials, SSO>({
  name: 'sso/upsert',
  method: 'POST',
  uri: '/sso',
})
