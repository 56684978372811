import asyncThunk from '@/lib/asyncThunk'

type Request = Record<never, never>

type Response = {
  widgetKey: string
  secretKey: string
  webhookSecret: string
}

export const accountDeveloperSettings = asyncThunk<Request, Response>({
  name: 'accounts/developer',
  uri: '/accounts/developer',
})
