import { FC } from 'react'

import Sumologic from '@/assets/logos/sumologic.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { Logging } from '@/out/logging'
import { LoggingProps } from '@/pages/settings/logging'

import SumologicDetails from './details'
import SumologicForm from './form'

type Props = LoggingProps<'sumologic'> & {
  configuration: Logging['sumologic']
}

const SumologicIntegration: FC<Props> = ({
  configuration,
  onSetup,
  setupTimer,
  onTest,
  testTimer,
  onRemove,
  removeTimer,
}) => {
  return (
    <SettingsCard<'logging'>
      title="Sumologic"
      description="Set up Sumologic integration to monitor your application's performance and logs."
      icon={<Sumologic className="text-[#632CA6] dark:text-white w-20" />}
      configuration={configuration}
      onRemove={() => onRemove('sumologic')}
      removeTimer={removeTimer}
    >
      {configuration ? (
        <SumologicDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('sumologic')} />
      ) : (
        <SumologicForm
          setupTimer={setupTimer}
          testTimer={testTimer}
          onSetup={config => onSetup('sumologic', config)}
          onTest={config => onTest('sumologic', config)}
        />
      )}
    </SettingsCard>
  )
}

export default SumologicIntegration
