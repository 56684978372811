import asyncThunk from '@/lib/asyncThunk'
import { Branding } from '@/out/branding'

type Request = {
  domain: string
}

type Response = Branding

export const updateDomain = asyncThunk<Request, Response>({
  name: 'branding/domain/update',
  method: 'PUT',
  uri: '/branding/domain',
})
