import clsx from 'clsx'
import { FC, ReactNode, useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts'

import { App } from '@/apps'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { DayCount, MetricNames } from '@/thunks/metrics/list'

export type Metric = {
  id: MetricNames<App>
  data: DayCount[]
  name: string
  color: string
}

type Props = {
  title: ReactNode
  slim?: boolean
  description?: string
  metrics: Metric[] | undefined
  cumulative?: boolean
  formatter?: (value: number, idx: number) => string
  className?: string
}

const Chart: FC<Props> = ({ title, slim, description, metrics, cumulative, formatter, className }) => {
  const totals = useMemo(() => {
    if (!metrics) return

    if (cumulative) {
      return metrics.reduce(
        (acc, metric) => {
          acc[metric.id] = metric.data[metric.data.length - 1].count
          return acc
        },
        {} as Record<MetricNames<App>, number>
      )
    }

    return metrics.reduce(
      (acc, metric) => {
        acc[metric.id] = metric.data.reduce((a, curr) => a + curr.count, 0)
        return acc
      },
      {} as Record<MetricNames<App>, number>
    )
  }, [metrics, cumulative])

  const data = useMemo(() => {
    if (!metrics) return

    const dates = Object.values(metrics)[0].data.map(({ date }) => date)
    return dates.map((date, idx) => ({
      date,
      ...metrics.reduce(
        (acc, metric) => {
          acc[metric.id] = metric.data[idx].count
          return acc
        },
        {} as Record<MetricNames<App>, number>
      ),
    }))
  }, [metrics])

  const config = useMemo(
    (): ChartConfig =>
      (metrics ?? []).reduce((acc, metric) => {
        acc[metric.id] = {
          label: metric.name,
        }
        return acc
      }, {} as ChartConfig),
    [metrics]
  )

  return (
    <Card className={className}>
      <CardHeader className="flex flex-col items-stretch p-0 space-y-0 border-b sm:flex-row">
        <div
          className={clsx('flex flex-col justify-center flex-1 gap-1', {
            'px-4 py-2': slim,
            'px-6 py-5 sm:py-6': !slim,
          })}
        >
          <CardTitle className={clsx(!!description || (slim ? 'text-base' : 'text-2xl'))}>{title}</CardTitle>
          {description && <CardDescription>{description}</CardDescription>}
        </div>
        <div className="flex">
          {metrics &&
            totals &&
            metrics.map((metric, idx) => {
              return (
                <div
                  key={metric.id}
                  className={clsx(
                    'relative z-30 flex flex-col justify-center flex-1 gap-1 text-right border-t sm:border-t-0',
                    {
                      'px-4 py-2 w-36': slim,
                      'px-6 py-4 sm:px-8 sm:py-6 even:border-l sm:border-l w-48': !slim,
                    }
                  )}
                >
                  {!slim && <span className="text-xs text-muted-foreground">{metric.name}</span>}
                  <span
                    className={clsx('leading-none', {
                      'font-semibold text-base': !slim,
                      'font-bold text-lg sm:text-3xl': !slim,
                    })}
                  >
                    {formatter ? formatter(totals[metric.id], idx) : totals[metric.id].toLocaleString()}
                  </span>
                </div>
              )
            })}
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer config={config} className="aspect-auto h-[250px] w-full">
          <BarChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={value => {
                const date = new Date(value)
                return date.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                })
              }}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className="w-[220px]"
                  formatter={
                    formatter
                      ? (value, name) =>
                        formatter(
                          +value.valueOf(),
                          metrics!.findIndex(m => m.id === name)
                        )
                      : undefined
                  }
                  labelFormatter={value => {
                    return new Date(value).toLocaleDateString('en-US', {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })
                  }}
                />
              }
            />
            {metrics &&
              metrics.map(metric => (
                <Bar key={metric.id} stackId={metric.id} dataKey={metric.id} fill={metric.color} />
              ))}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

export default Chart
