import clsx from 'clsx'
import { ReactNode } from 'react'
import { ControllerRenderProps, FieldPath, FieldValues, useFormContext } from 'react-hook-form'

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { cn } from '@/lib/utils'

type Props<T extends FieldValues> = {
  label: ReactNode
  name: FieldPath<T>
  placeholder?: string
  type?: 'number'
  textarea?: number
  prefix?: string
  suffix?: string
  aside?: ReactNode
  hint?: string
  inputClassName?: string
  className?: string
  children?: (field: ControllerRenderProps<T, FieldPath<T>>) => JSX.Element
}

function Field<T extends FieldValues>({
  label,
  name,
  placeholder,
  type,
  textarea,
  prefix,
  suffix,
  aside,
  hint,
  inputClassName,
  className,
  children,
}: Props<T>) {
  const form = useFormContext<T>()

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }: { field: ControllerRenderProps<T, FieldPath<T>> }) => (
        <FormItem className={className}>
          <FormLabel className="flex items-center justify-between">
            {label}
            {aside && <aside>{aside}</aside>}
          </FormLabel>
          <FormControl>
            {children ? (
              children(field)
            ) : prefix || suffix ? (
              <div className={clsx('flex rounded-md group focus:outline', inputClassName)}>
                {prefix && (
                  <div
                    className={cn(
                      'flex h-9 rounded-md border border-input border-r-0 rounded-r-none items-center bg-muted-foreground/5 px-3 py-1 text-sm shadow-sm transition-colors'
                    )}
                  >
                    {prefix}
                  </div>
                )}
                <Input
                  placeholder={placeholder}
                  autoComplete="one-time-code"
                  autoCapitalize="off"
                  {...field}
                  type={type}
                  className={clsx('flex-1 focus-visible:ring-0', {
                    'rounded-r-none': !!suffix,
                    'rounded-l-none': !!prefix,
                  })}
                  onChange={
                    type === 'number' ? e => field.onChange(e.target.value ? +e.target.value : undefined) : undefined
                  }
                />
                {suffix && (
                  <div
                    className={cn(
                      'flex h-9 rounded-md border border-input border-l-0 rounded-l-none items-center bg-muted-foreground/5 px-3 py-1 text-sm shadow-sm transition-colors'
                    )}
                  >
                    {suffix}
                  </div>
                )}
              </div>
            ) : textarea ? (
              <Textarea
                placeholder={placeholder}
                autoComplete="one-time-code"
                {...field}
                rows={textarea}
                className={inputClassName}
              />
            ) : (
              <Input
                placeholder={placeholder}
                autoComplete="one-time-code"
                autoCapitalize="off"
                {...field}
                type={type}
                className={inputClassName}
              />
            )}
          </FormControl>
          {hint && <FormDescription>{hint}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export default Field
