import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { SSOProvider } from '@/out/single-sign-on'
import { deleteSSO } from '@/thunks/single-sign-on/delete'

export default function useSSORemove(onRemove: (provider: SSOProvider) => void) {
  const [remove, { timer }] = useAPI(deleteSSO)

  const handleDelete = useCallback(
    async (provider: SSOProvider) => {
      timer.setId(provider)

      await remove({ provider })

      toast.warning(`${provider} has been disconnected`)

      onRemove(provider)
    },
    [timer, remove, onRemove]
  )

  return [handleDelete, { timer }] as const
}
