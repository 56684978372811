import clsx from 'clsx'
import { FC } from 'react'

import useGhostMode from '@/hooks/useIsGhost'

const GhostMode: FC = () => {
  const [isGhost, { unghost, unghostTimer: timer }] = useGhostMode()

  if (!isGhost) return null

  return (
    <div className="absolute inset-0 flex items-start justify-center h-0 text-sm text-muted-foreground">
      <button
        disabled={timer.isLoading}
        className={clsx('px-4 py-1 text-xs text-white bg-orange-500 rounded-b-md z-999', {
          'opacity-70': timer.isLoading,
        })}
        onClick={unghost}
      >
        You&apos;re in ghost mode
      </button>
    </div>
  )
}

export default GhostMode
