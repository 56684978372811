'use client'

import { FC } from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'

const SupportTicket: FC = () => {
  return (
    <div className="container max-w-2xl px-4 py-8 mx-auto">
      <header className="mb-8 text-center">
        <h1 className="mb-2 text-3xl font-bold">Submit a Support Ticket</h1>
        <p className="text-muted-foreground">Please provide details about your issue</p>
      </header>

      <form className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="subject">Subject</Label>
          <Input id="subject" placeholder="Brief description of the issue" required />
        </div>

        <div className="space-y-2">
          <Label htmlFor="priority">Priority</Label>
          <Select onValueChange={() => {}} required>
            <SelectTrigger>
              <SelectValue placeholder="Select priority" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="low">Low</SelectItem>
              <SelectItem value="medium">Medium</SelectItem>
              <SelectItem value="high">Critical (Production Outage)</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            placeholder="Provide detailed information about your issue"
            required
            className="min-h-[150px]"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="file">Attach File (optional)</Label>
          <Input id="file" type="file" />
        </div>

        <Button type="submit" className="w-full">
          Submit Ticket
        </Button>
      </form>
    </div>
  )
}

export default SupportTicket
