import { useCallback, useState } from 'react'

import { useAPI } from '@/hooks/useAPI'
import { isErrorResponse } from '@/lib/asyncThunk'
import { testCustomerLookup } from '@/thunks/customer-lookup/test'

import { FormData } from './'
import { Customer } from './TestCard'

const fixtures: Customer[] = [
  { id: '1', title: 'Alice Johnson', description: 'Osage Dr, 80303' },
  { id: '2', title: 'Bob Smith', description: 'Pickle Lane, 38190' },
  { id: '3', title: 'Charlie Brown', description: 'Pine St, 48181' },
]

export default function useTestLookup() {
  const [customers, setCustomers] = useState<Customer[] | undefined>(fixtures)
  const [error, setError] = useState<string>()

  const [test, { timer }] = useAPI(testCustomerLookup)
  const handleSubmit = useCallback(
    async (phoneNumber: string, data: FormData) => {
      setCustomers(undefined)
      setError(undefined)

      try {
        const [{ customers }] = await test({ phoneNumber, ...data })

        setCustomers(customers)
      } catch (err) {
        if (isErrorResponse(err)) {
          console.log('err', err.error)
          setError(err.error)
        }
      }
    },
    [test]
  )

  return [customers, handleSubmit, { timer, error }] as const
}
