import clsx from 'clsx'
import { Copy, Eye, EyeOff } from 'lucide-react'
import { FC, useState } from 'react'

import CopyButton from '@/components/copy-button'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'

const iconClass =
  'absolute w-4 h-4 transform -translate-y-1/2 cursor-pointer top-1/2 text-muted-foreground hover:text-foreground'

type Props = {
  webhookSecret: string
}

const WebhookSecretCard: FC<Props> = ({ webhookSecret }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Card className="flex flex-col shadow-none min-w-md max-w-lg">
      <CardHeader className="pb-4">
        <CardTitle>Webhook Secret</CardTitle>
        <CardDescription>
          This secret is used to verify the authenticity of incoming webhook requests. An{' '}
          <span className="font-mono">X-Signature</span> header will be included with webhook requests.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col flex-grow overflow-auto">
        <div className="relative">
          <Input
            type="text"
            value={isVisible ? webhookSecret : webhookSecret.replace(/_(.+)$/, () => `_${'*'.repeat(32)}`)}
            readOnly
            className="bg-muted-foreground/5 pr-20 font-mono truncate"
          />
          {isVisible ? (
            <EyeOff className={clsx(iconClass, 'right-10')} onClick={() => setIsVisible(false)} />
          ) : (
            <Eye className={clsx(iconClass, 'right-10')} onClick={() => setIsVisible(true)} />
          )}

          <CopyButton text={webhookSecret}>
            <Copy className="top-1/2 right-2 absolute w-4 h-4 text-muted-foreground hover:text-foreground transform -translate-y-1/2 cursor-pointer" />
          </CopyButton>
        </div>
        <div className="mt-4">
          <Button size="sm" variant="outline">
            View code examples to verify the signature
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default WebhookSecretCard
