import { format, parseISO } from 'date-fns'
import { ArrowLeftToLine, ChevronRight, DownloadIcon } from 'lucide-react'
import { FC, useCallback, useState } from 'react'

import { appNames } from '@/apps'
import SettingsLayout from '@/components/layout/settings-layout'
import LoadingButton from '@/components/loading-button'
import SettingsTitle from '@/components/settings-title'
import { useAPI } from '@/hooks/useAPI'
import useDownload from '@/hooks/useDownload'
import useList from '@/hooks/useList'
import s from '@/styles/table.module.scss'
import { exportAuditLog } from '@/thunks/audit-log/export'
import { auditLog } from '@/thunks/audit-log/list'

const AuditLog: FC = () => {
  const [lastKey, setLastKey] = useState<string>()
  const [events, { headers }] = useList(auditLog, { lastKey })

  const [expo, { timer }] = useAPI(exportAuditLog)
  const download = useDownload()
  const handleExport = useCallback(async () => {
    const [csv] = await expo({
      lastKey,
      timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const filename = `audit-log-${format(new Date(), 'yyyy-mm-dd')}.csv`
    download('csv', filename, csv)
  }, [lastKey, download, expo])

  return (
    <SettingsLayout className="max-w-7xl">
      <SettingsTitle title="Audit log">
        <LoadingButton timer={timer} size="sm" variant="outline" onClick={handleExport}>
          <DownloadIcon className="w-4 h-4 mr-2" />
          Export
        </LoadingButton>
      </SettingsTitle>
      <div className={s.Table}>
        <div>
          <table className="!text-xs">
            <thead>
              <tr>
                <th className="w-48">
                  <div className="">Date</div>
                </th>
                <th className="w-40">
                  <div className="">Admin</div>
                </th>
                <th>
                  <div className="">Description</div>
                </th>
                <th className="w-56">
                  <div className="">Event</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {events &&
                events.map((event, i) => (
                  <tr key={i} data-id={event.id}>
                    <td>{format(parseISO(event.timestamp), 'MMM d, yyyy h:mma')}</td>
                    <td>
                      <div className="w-[80px]">
                        {event.admin ? `${event.admin.firstName} ${event.admin.lastName}` : 'Journey'}
                      </div>
                    </td>
                    <td className="flex items-center space-x-2">
                      {event.namespace !== 'global' && (
                        <div className="inline-flex items-center rounded-md border px-1.5 py-0.5 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-foreground text-nowrap">
                          {appNames[event.namespace]}
                        </div>
                      )}
                      <span className="max-w-[400px] text-foreground truncate font-medium">{event.description}</span>
                    </td>
                    <td>{event.event}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between px-2 mt-2">
        <div className="flex-1 text-sm text-muted-foreground">
          {events?.length} of {headers?.['x-count']} events
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="inline-flex items-center justify-center w-8 h-8 p-0 text-sm font-medium transition-colors border rounded-md shadow-sm whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground"
            onClick={() => setLastKey(undefined)}
            disabled={!lastKey}
          >
            <span className="sr-only">Go to next page</span>
            <ArrowLeftToLine className="w-4 h-4" />
          </button>
          <button
            className="inline-flex items-center justify-center w-8 h-8 p-0 text-sm font-medium transition-colors border rounded-md shadow-sm whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground"
            onClick={() => headers && setLastKey(headers['x-last-key'])}
            disabled={headers && !headers['x-last-key']}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default AuditLog
