import { CheckIcon, Rocket } from 'lucide-react'
import { FC, useCallback, useState } from 'react'
import { toast } from 'sonner'

import LoadingButton from '@/components/loading-button'
import { Button } from '@/components/ui/button'
import { ActionTimer } from '@/hooks/useActionTimer'

type Props = {
  timer: ActionTimer
  onTest: () => Promise<boolean>
}

const TestDatadog: FC<Props> = ({ timer, onTest }) => {
  const [testPassed, setTestPassed] = useState(false)

  const handleTest = useCallback(async () => {
    const wasSuccessful = await onTest()
    if (wasSuccessful) {
      setTestPassed(true)
    } else {
      toast.error('Test failed. Please check your credentials and try again.')
    }
  }, [onTest])

  if (testPassed) {
    return (
      <Button className="flex-1 text-white bg-green-500 pointer-events-none">
        <CheckIcon className="w-4 h-4 mr-2" />
        Test passed
      </Button>
    )
  }

  return (
    <LoadingButton timer={timer} variant="outline" className="flex-1" onClick={handleTest}>
      <Rocket className="w-4 h-4 mr-2" />
      Test Integration
    </LoadingButton>
  )
}

export default TestDatadog
