import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import apps, { App } from '@/apps'
import { switchApp } from '@/thunks/apps/switch'

let initialApp: App | undefined
for (const app of apps) {
  if (window.location.pathname.startsWith('/' + app.id)) {
    initialApp = app.id
    break
  }
}

type AppState = {
  app: App | undefined
}

const initialState: AppState = {
  app: initialApp,
}

const setApp: CaseReducer<AppState, PayloadAction<App | undefined>> = (_, action) => {
  return {
    app: action.payload,
  }
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setApp,
  },
  extraReducers: builder => {
    builder.addCase(switchApp.fulfilled, (_, { payload }) => {
      return { app: payload }
    })
  },
})

export default slice

export const actions = slice.actions
