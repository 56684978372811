import { FC, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router'
import r from 'routes'

import WatchTowerHeader from '@/components/layout/watch-tower-header'
import { SettingsContainer } from '@/components/settings-nav'
import useGhostMode from '@/hooks/useIsGhost'

import WatchTowerAccountsList from './accounts/list/index'
import WatchTowerAccountsShow from './accounts/show'
import WatchTowerDashboard from './dashboard'

const p = (path: string) => path.substring(12)

const WatchTower: FC = () => {
  const [isGhost] = useGhostMode()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isGhost) return
    navigate(r.root)
  }, [isGhost, navigate])

  if (isGhost) return null

  return (
    <>
      <WatchTowerHeader />
      <SettingsContainer>
        <Routes>
          <Route path={p(r.watchTower.root)} element={<WatchTowerDashboard />} />
          <Route path={p(r.watchTower.accounts.root)} element={<WatchTowerAccountsList />} />
          <Route path={p(r.watchTower.accounts.show())} element={<WatchTowerAccountsShow />} />
        </Routes>
      </SettingsContainer>
    </>
  )
}

export default WatchTower
