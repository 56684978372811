import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { LoggingDestination } from '@/out/logging'
import { deleteLogging } from '@/thunks/logging/delete'

export default function useLoggingRemove(onRemove: (destination: LoggingDestination) => void) {
  const [remove, { timer }] = useAPI(deleteLogging)

  const handleDelete = useCallback(
    async (destination: LoggingDestination) => {
      timer.setId(destination)

      await remove({ destination })

      toast.warning(`${destination} has been disconnected`)

      onRemove(destination)
    },
    [timer, remove, onRemove]
  )

  return [handleDelete, { timer }] as const
}
