import { FC, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'

import LoadingButton from '@/components/loading-button'
import { useAPI } from '@/hooks/useAPI'
import { APIError, isErrorResponse } from '@/lib/asyncThunk'
import { actions } from '@/slices/auth'
import { useAppDispatch } from '@/store'
import { oauthCallback } from '@/thunks/oauth/callback'

type Props = {
  disabled?: boolean
  children: ReactNode
  onError?: (error: string) => void
}

const TheButton: FC<Props> = ({ disabled, children, onError }) => {
  const [authenticate, { timer }] = useAPI(oauthCallback)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSuccess = useCallback(
    async (resp: CodeResponse) => {
      try {
        const [{ token }] = await authenticate({
          provider: 'google',
          code: resp.code,
        })

        dispatch(actions.setJWT(token))
        navigate('/')
      } catch (error) {
        if (isErrorResponse(error)) {
          if (error.code === 'ACCOUNT_EXISTS') {
            onError?.('An account already exists for your company. Please contact your administrator to get access.')
          } else {
            onError?.(error.error)
          }
        } else {
          onError?.((error as APIError<FormData>).error ?? 'An unknown error occurred')
        }
      }
    },
    [authenticate, dispatch, navigate, onError]
  )

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    enable_serial_consent: false,
    overrideScope: true,
  })

  return (
    <LoadingButton size="lg" variant="outline" disabled={disabled} timer={timer} className="w-full" onClick={login}>
      {children}
    </LoadingButton>
  )
}

const GoogleButton: FC<Props> = props => (
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID || ''}>
    <TheButton {...props} />
  </GoogleOAuthProvider>
)

export default GoogleButton
