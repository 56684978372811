import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'

import { AppStatus } from '../admins/bootstrap'

type Request = {
  app: App
}

type Response<T extends AppStatus = AppStatus> = {
  status: AppStatus
  credits: T extends 'free-trial' ? number : never
  freeTier: T extends 'free-trial' ? number : never
}

export const billingUsage = asyncThunk<Request, Response>({
  name: 'billing/usage',
  uri: ({ app }) => `/${app}/billing/usage`,
  body: ({ app: _, ...rest }) => rest,
})
