import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { deleteSMS } from '@/thunks/sms/delete'

export default function useSMSDelete(onRemove: () => void) {
  const [remove, { timer }] = useAPI(deleteSMS)

  const handleDelete = useCallback(async () => {
    await remove({})

    toast.warning('Twilio has been disconnected')

    onRemove()
  }, [remove, onRemove])

  return [handleDelete, { timer }] as const
}
