import { Cable } from 'lucide-react'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { FormProps } from '@/pages/settings/logging'
import TestSumologic from './test'

const schema = z
  .object({
    collectorUrl: z.string().min(1, { message: 'Collector URL is required' }),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = FormProps<'sumologic'>

const SumologicForm: FC<Props> = ({ onSetup, setupTimer, onTest, testTimer }) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      collectorUrl: '',
    },
    mode: 'onChange',
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSetup)} className="mt-6 space-y-4">
        <Field<FormData> label="Collector URL" name="collectorUrl" placeholder="Enter your Sumologic collector URL" />

        <div className="flex pt-2 space-x-4">
          <TestSumologic timer={testTimer} onTest={() => onTest(form.getValues())} />
        </div>
        <div>
          <LoadingButton timer={setupTimer} type="submit" className="w-full">
            <Cable className="w-4 h-4 mr-2" />
            Connect Sumologic
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}

export default SumologicForm
