import { FC } from 'react'

import LoadingButton from '@/components/loading-button'
import { SMSConfiguration } from '@/out/sms'
import useSMSDelete from '@/pages/settings/sms-configuration/twilio/useSMSDelete'
import formatPhoneNumber from '@/util/format-phone-number'

type Props = {
  twilio: SMSConfiguration['twilio']
  onRemove: () => void
}

const TwilioDetails: FC<Props> = ({ twilio, onRemove }) => {
  const [remove, { timer }] = useSMSDelete(onRemove)

  return (
    <div>
      <div className="mb-6 space-y-3 ">
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Account SID</span>
          <span className="text-sm font-medium">{twilio.accountSid}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Phone Number</span>
          <span className="text-sm font-medium">{formatPhoneNumber(twilio.phoneNumber)}</span>
        </div>
      </div>
      <LoadingButton
        timer={timer}
        variant="secondary"
        size="sm"
        onClick={remove}
        className="text-xs hover:bg-destructive hover:text-background"
      >
        Disconnect
      </LoadingButton>
    </div>
  )
}

export default TwilioDetails
