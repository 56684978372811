import { useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'

import { useAPI } from '@/hooks/useAPI'
import { LoggingDestination } from '@/out/logging'
import { testLogging } from '@/thunks/logging/test'
import { LoggingCredentials } from '@/thunks/logging/upsert'

export default function useLoggingTest<T extends LoggingDestination>() {
  const [test, { timer }] = useAPI(testLogging)

  const handleTest = useCallback(
    async (destination: T, config: NonUndefined<LoggingCredentials[T]>): Promise<boolean> => {
      timer.setId(destination)
      const [res] = await test({ destination, [destination]: config })
      return res.wasSuccessful
    },
    [timer, test]
  )

  return [handleTest, { timer }] as const
}
