import clsx from 'clsx'
import { FC, ReactNode } from 'react'

type Props = {
  isActive: boolean
  children: ReactNode
  onClick: (e: React.MouseEvent) => void
}

const NavLink: FC<Props> = ({ isActive, children, onClick }) => {
  return (
    <span
      className={clsx(
        'px-3 py-2 rounded-md text-sm transition-colors flex items-center [&>svg]:w-4 [&>svg]:h-4 [&>svg]:mr-2 cursor-pointer',
        isActive
          ? 'text-foreground bg-muted font-medium'
          : 'text-muted-foreground hover:text-foreground hover:bg-muted/50'
      )}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export default NavLink
