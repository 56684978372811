import asyncThunk from '@/lib/asyncThunk'
import { SSOProvider } from '@/out/single-sign-on'

type Request = {
  provider: SSOProvider
}

export const deleteSSO = asyncThunk<Request, void>({
  name: 'sso/delete',
  method: 'DELETE',
  uri: '/sso',
})
