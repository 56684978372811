import { createSlice } from '@reduxjs/toolkit'

import { App } from '@/apps'
import { AppConfig, bootstrapAdmin } from '@/thunks/admins/bootstrap'
import { startFreeTrial } from '@/thunks/billing/free-trial'

type BillingState = Record<App, AppConfig>

const initialState: BillingState = {
  'document-signature': { status: 'inactive' },
  'e-form': { status: 'inactive' },
  'file-upload': { status: 'inactive' },
  'one-time-passcode': { status: 'inactive' },
  passkeys: { status: 'inactive' },
  payments: { status: 'inactive' },
}

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(bootstrapAdmin.fulfilled, (_, action) => action.payload.data.billing)
    builder.addCase(startFreeTrial.fulfilled, (state, action) => {
      state[action.meta.arg.app] = action.payload.data
      return state
    })
  },
})

export default slice
