import { FC } from 'react'

const DataProcessingAddendum: FC = () => {
  return (
    <div className="relative flex w-full min-h-screen p-4 bg-secondary/50">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div
          className="absolute inset-0 -z-10"
          style={{
            background: 'radial-gradient(600px circle at center, transparent, rgba(0,42,78,0.05))',
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            background: `
              linear-gradient(90deg, rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
              linear-gradient(rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px
            `,
            maskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
            WebkitMaskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
          }}
        />
      </div>
      <div className="flex items-center justify-center flex-1">
        <div className="relative w-full max-w-4xl p-8 mb-12 space-y-4 overflow-hidden text-center bg-white border-2 border-white shadow-md rounded-xl">
          <div className="-top-20 left-1/2 absolute w-[200%] h-80 -translate-x-1/2 overflow-hidden">
            <div className="-z-5 absolute inset-0 bg-gradient-to-b from-[#002A4E]/5 to-transparent" />
            <div
              className="absolute inset-0"
              style={{
                background: `
        linear-gradient(90deg, rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
        linear-gradient(rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px
      `,
                maskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
                WebkitMaskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
              }}
            />
          </div>
          <div className="prose text-left lg:prose-xl">
            <h3>Data Processing Addendum</h3>
            <p className="text-base">Last updated: January 14, 2025</p>

            <ol>
              <li>
                <strong>Purpose</strong>
                <br />
                This Data Processing Addendum outlines the obligations of the Parties regarding the processing and
                protection of personal data in relation to the use of the SaaS application.
              </li>
              <li>
                <strong>Definitions</strong>
                <br />
                a. &quot;Personal Data&quot; means any data relating to an identified or identifiable individual that is
                collected, processed, or accessed by either Party under this Addendum.
                <br />
                b. &quot;Parties&quot; means Journey AI, Inc. d/b/a &quot;Journey&quot; and the Customer.
                <br />
                c. &quot;Processing&quot; means any operation or set of operations performed on Personal Data, including
                but not limited to collection, storage, transfer, and deletion.
              </li>
              <li>
                <strong>Scope of Processing</strong>
                <br />
                Journey will only process Personal Data on behalf of the Customer to provide the SaaS services and in
                accordance with the Customer&apos;s instructions and this Addendum.
              </li>
              <li>
                <strong>Security Measures</strong>
                <br />
                Journey will implement technical and organizational measures to protect Personal Data against
                unauthorized or unlawful processing, accidental loss, destruction, or damage. These measures will be
                designed to ensure the ongoing confidentiality, integrity, and availability of Personal Data.
              </li>
              <li>
                <strong>Subprocessors</strong>
                <br />
                Journey may engage Subprocessors to fulfill its obligations under this Addendum. Journey will ensure
                that each Subprocessor is bound by written obligations that provide a level of protection for Personal
                Data consistent with this Addendum.
              </li>
              <li>
                <strong>International Transfers</strong>
                <br />
                If Personal Data is transferred across jurisdictions, Journey will ensure an adequate level of
                protection by implementing appropriate transfer mechanisms. Such transfers will comply with applicable
                data protection laws.
              </li>
              <li>
                <strong>Data Retention</strong>
                <br />
                Journey will retain Personal Data only for as long as necessary to provide the SaaS services or as
                otherwise required by law. Upon termination of services or upon request, Journey will securely delete or
                return all Personal Data, except where continued retention is legally required.
              </li>
              <li>
                <strong>Data Breach Notification</strong>
                <br />
                If Journey becomes aware of a data breach affecting Personal Data, Journey will promptly notify the
                Customer. The notification will include, where possible, the categories and approximate number of
                individuals affected, the likely consequences of the breach, and any measures taken or proposed to
                address the breach.
              </li>
              <li>
                <strong>Audits and Compliance</strong>
                <br />
                Journey will, upon reasonable request, provide the Customer with information or certifications
                demonstrating compliance with this Addendum. Customer-initiated audits will be conducted in a manner
                that does not disrupt Journey&apos;s normal business operations.
              </li>
              <li>
                <strong>Amendments</strong>
                <br />
                Any amendments to this Addendum must be agreed to in writing and signed by authorized representatives of
                both Parties.
              </li>
              <li>
                <strong>Termination</strong>
                <br />
                Upon termination of the SaaS services, Journey will discontinue all processing of Personal Data unless
                required to retain certain data by law. This Addendum remains in effect for any retained Personal Data
                until it is deleted or returned.
              </li>
            </ol>

            <h3>Subprocessor List</h3>
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  <th className="w-1/4">Subprocessor</th>
                  <th className="w-3/4">Data Processing Addendum Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AWS</td>
                  <td>
                    <a
                      href="https://d1.awsstatic.com/legal/aws-dpa/aws-dpa.pdf"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://d1.awsstatic.com/legal/aws-dpa/aws-dpa.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Cisco</td>
                  <td>
                    <a
                      href="https://trustportal.cisco.com/c/dam/r/ctp/docs/dataprotection/cisco-master-data-protection-agreement.pdf"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://trustportal.cisco.com/c/dam/r/ctp/docs/dataprotection/cisco-master-data-protection-agreement.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Datadog</td>
                  <td>
                    <a
                      href="https://www.datadoghq.com/legal/data-processing-addendum/"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.datadoghq.com/legal/data-processing-addendum/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Five9</td>
                  <td>
                    <a
                      href="https://www.five9.com/legal/privacy-addendum"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.five9.com/legal/privacy-addendum
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Genesys</td>
                  <td>
                    <a
                      href="https://help.mypurecloud.com/wp-content/uploads/2020/10/Genesys-Data-Processing-Addendum.pdf"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://help.mypurecloud.com/wp-content/uploads/2020/10/Genesys-Data-Processing-Addendum.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Google Workspace</td>
                  <td>
                    <a
                      href="https://workspace.google.com/terms/09242021/dpa_terms/"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://workspace.google.com/terms/09242021/dpa_terms/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Monday</td>
                  <td>
                    <a
                      href="https://monday.com/l/privacy/dpa/"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://monday.com/l/privacy/dpa/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Salesforce</td>
                  <td>
                    <a
                      href="https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/data-processing-addendum.pdf"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/data-processing-addendum.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Sentry</td>
                  <td>
                    <a
                      href="https://sentry.io/legal/dpa/"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://sentry.io/legal/dpa/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Slack</td>
                  <td>
                    <a
                      href="https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/data-processing-addendum.pdf"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/data-processing-addendum.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Twilio</td>
                  <td>
                    <a
                      href="https://www.twilio.com/en-us/legal/data-protection-addendum"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.twilio.com/en-us/legal/data-protection-addendum
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Zendesk</td>
                  <td>
                    <a
                      href="https://www.zendesk.com/company/data-processing-form/"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.zendesk.com/company/data-processing-form/
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Zoom</td>
                  <td>
                    <a
                      href="https://media.zoom.com/download/assets/zoom-global-dpa.pdf/dd327ebea27e11efb613d6ba63ed4cee"
                      className="block truncate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://media.zoom.com/download/assets/zoom-global-dpa.pdf/dd327ebea27e11efb613d6ba63ed4cee
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataProcessingAddendum
