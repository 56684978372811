import zoomSDK from '@zoom/appssdk'

const configureZoom = async () => {
  const zoomConfig = await zoomSDK.config({
    capabilities: [
      'authorize',
      'getAppContext',
      'getEngagementContext',
      'getEngagementStatus',
      'getPhoneContext',
      'getRunningContext',
      'onAuthorized',
      'onEngagementContextChange',
      'onEngagementStatusChange',
      'onPhoneCalleeAnswered',
      'onPhoneCalleeEnded',
      'onPhoneCallerEnded',
      'onPhoneContext',
      'openUrl',
    ],
    version: '0.16.0',
  })
  return zoomConfig
}

export default configureZoom
