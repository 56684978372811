import parsePhoneNumber from 'libphonenumber-js/min'

export default function formatPhoneNumber(number: string): string {
  if (!number) return ''
  const parsed = parsePhoneNumber(number)
  if (!parsed) return number
  const countryCode = parsed.countryCallingCode
  const formatted = parsed.formatNational()
  return `+${countryCode} ${formatted}`
}
