import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

export function useQueryParams<T extends Record<string, string>>() {
  const [searchParams] = useSearchParams()

  const params = useMemo(() => {
    const params: T = {} as T
    searchParams.forEach((value, key) => {
      params[key as keyof T] = value as T[keyof T]
    })
    return params
  }, [searchParams])

  return params
}
