import { useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { SSO, SSOProvider } from '@/out/single-sign-on'
import { upsertSSO } from '@/thunks/single-sign-on/upsert'

type SetupFunc<T extends SSOProvider> = (provider: T, config: NonUndefined<SSO[T]>) => void

export default function useSSOUpsert<T extends SSOProvider>(onSetup: SetupFunc<T>) {
  const [upsert, { timer }] = useAPI(upsertSSO)

  const handleUpsert = useCallback(
    async (provider: T, config: NonUndefined<SSO[T]>): Promise<SSO> => {
      timer.setId(provider)

      const [res] = await upsert({ provider, [provider]: config })

      toast.success(`${provider} has been configured`)

      const conf = res[provider] as NonUndefined<SSO[T]>
      onSetup(provider, conf)

      return res
    },
    [timer, upsert, onSetup]
  )

  return [handleUpsert, { timer }] as const
}
