import { CircleArrowLeft } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import r from 'routes'

// import MobileNav from './mobile-nav'
import AppSelector from '@/components/layout/app-selector'
import { useAppSelector, useBillingSelector } from '@/store'

import { Button } from '../ui/button'
import AccountDropdown from './account-dropdown'
import CreditInfo from './credit-info'
import DesktopNav from './desktop-nav'
import GhostMode from './ghost'
import InactiveAppNav from './inactive-app-nav'

const LayoutHeader: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { app } = useAppSelector()
  const billing = useBillingSelector()

  const status = useMemo(() => (app && app in billing ? billing[app].status : undefined), [app, billing])

  return (
    <header className="sticky top-0 z-50 flex items-center gap-3 p-3 border-b bg-background border-border">
      {pathname.startsWith(r.settings.root) ? (
        <div
          className="flex items-center pl-4 space-x-2 cursor-pointer h-9 text-foreground/70 hover:text-foreground"
          onClick={() => navigate(r.root)}
        >
          <CircleArrowLeft size={16} />
          <span className="text-sm font-medium">Back to apps</span>
        </div>
      ) : (
        <AppSelector />
      )}
      {status !== 'inactive' ? <DesktopNav /> : <InactiveAppNav />}
      <div className="flex items-center gap-1.5">
        {status === 'free-trial' && <CreditInfo />}

        <Button size="sm" variant="outline" onClick={() => navigate(r.sales.root)}>
          Contact sales
        </Button>

        <AccountDropdown />
      </div>
      <GhostMode />
    </header>
  )
}

export default LayoutHeader
