import { cn, formatPlanName } from '@/lib/utils'
import { AppStatus } from '@/thunks/admins/bootstrap'

interface PlanBadgeProps {
  plan: AppStatus
  className?: string
}

const getPlanStyles = (plan: AppStatus) => {
  switch (plan) {
    case 'free-trial':
      return 'border-yellow-500 bg-yellow-100 text-yellow-800 dark:border-yellow-400 dark:bg-yellow-400/20 dark:text-yellow-200'
    case 'subscription':
      return 'border-green-500 bg-green-100 text-green-800 dark:border-green-400 dark:bg-green-400/20 dark:text-green-200'
    case 'enterprise':
      return 'border-blue-500 bg-blue-100 text-blue-800 dark:border-blue-400 dark:bg-blue-400/20 dark:text-blue-200'
    case 'inactive':
    case 'cancelled':
      return 'border-gray-500 bg-gray-100 text-gray-800 dark:border-gray-400 dark:bg-gray-400/20 dark:text-gray-200'
  }
}

export function PlanBadge({ plan, className }: PlanBadgeProps) {
  return (
    <div
      className={cn(
        'shadow-none px-3 h-6 font-light text-xs rounded-full border flex items-center cursor-default',
        getPlanStyles(plan),
        className
      )}
    >
      {formatPlanName(plan)}
    </div>
  )
}
