import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'

import { AppStatus } from '../admins/bootstrap'

type Request = {
  app: App
}

type Response = {
  status: AppStatus
  credits: number
}

export const startFreeTrial = asyncThunk<Request, Response>({
  name: 'billing/free-trial',
  uri: ({ app }) => `/${app}/billing/free-trial`,
  method: 'POST',
  body: ({ app: _, ...rest }) => rest,
})
