import clsx from 'clsx'
import { Zap } from 'lucide-react'
import { FC, useCallback } from 'react'
import { Link, useNavigate } from 'react-router'
import r from 'routes'

import apps, { App, appColors, appIcons } from '@/apps'
import LoadingButton from '@/components/loading-button'
import {
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,
} from '@/components/ui/card'
import { idleTimer } from '@/hooks/useActionTimer'
import { useAPI } from '@/hooks/useAPI'
import { useBillingSelector } from '@/store'
import { startFreeTrial } from '@/thunks/billing/free-trial'

const appIds = ['passkeys', 'one-time-passcode', 'document-signature', 'e-form', 'payments'] as const

const appCards = appIds.map(appId => {
  const app = apps.find(app => app.id === appId)
  if (!app) throw new Error(`App not found: ${appId}`)

  return {
    app: appId,
    icon: appIcons[appId],
    title: app.title,
    color: appColors[app.color],
  }
})

const descriptions = {
  passkeys: 'Authenticate customers with their smartphone.',
  'document-signature': 'Request customers to sign documents.',
  'one-time-passcode': 'Update customer authentication in a few hours.',
  payments: 'Accept digital payments in the contact center.',
  'e-form': 'Collect sensitive data without exposing it to agents.',
} as const

const Home: FC = () => {
  const navigate = useNavigate()
  const [start, { timer }] = useAPI(startFreeTrial)

  const apps = useBillingSelector()

  const handleStartFreeTrial = useCallback(
    async (app: App) => {
      timer.setId(app)
      await start({ app })
      navigate(r.apps[app].root)
    },
    [start, timer, navigate]
  )

  return (
    <div className="w-full px-4 py-12 mx-auto md:max-w-7xl">
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {appCards.map(({ app, icon: Icon, ...card }) => (
          <Card key={card.title} className="cursor-pointer bg-muted/10" onClick={() => navigate(r.apps[app].root)}>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 font-medium">
                <Icon className={clsx('w-6 h-6', card.color)} />
                {card.title}
              </CardTitle>
              <CardDescription>{descriptions[app]}</CardDescription>
            </CardHeader>
            <CardContent className="h-64">
              <iframe
                src="https://player.vimeo.com/video/930753291?h=d70d88cac8"
                width="100%"
                height="100%"
                allow="autoplay; fullscreen; picture-in-picture"
              ></iframe>
            </CardContent>
            <CardFooter className="justify-center p-0">
              {apps[app].status === 'inactive' ? (
                <LoadingButton
                  timer={timer.id === app ? timer : idleTimer}
                  variant="ghost"
                  className="w-full text-sm font-medium text-center h-11 hover:bg-muted/50 group"
                  onClick={() => handleStartFreeTrial(app)}
                >
                  <span className="flex items-center justify-center">
                    <Zap className="w-4 h-4 mr-2 transition-colors duration-300 group-hover:fill-foreground fill-transparent" />{' '}
                    Start Free Trial
                  </span>
                </LoadingButton>
              ) : (
                <Link
                  to={r.apps[app].root}
                  className="w-full py-3 text-sm font-medium text-center hover:bg-muted/50 group"
                >
                  Launch App
                </Link>
              )}
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Home
