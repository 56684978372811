import { FC } from 'react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'

const Sales: FC = () => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Here you would typically handle form submission
    console.log('Form submitted')
  }

  return (
    <div className="container max-w-2xl px-4 py-12 mx-auto">
      <header className="mb-12 text-center">
        <h1 className="mb-4 text-4xl font-bold">Request Sales to Reach Out</h1>
        <p className="text-muted-foreground">
          Please provide your information and we&apos;ll have our sales team contact you soon.
        </p>
      </header>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="interest">Interest *</Label>
          <Select required>
            <SelectTrigger>
              <SelectValue placeholder="Select your primary interest" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="product">Product Information</SelectItem>
              <SelectItem value="pricing">Pricing</SelectItem>
              <SelectItem value="demo">Request a Demo</SelectItem>
              <SelectItem value="partnership">Partnership Opportunities</SelectItem>
              <SelectItem value="other">Other</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="phone">Phone Number</Label>
          <Input id="phone" type="tel" placeholder="Your phone number (optional)" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="message">Additional Information</Label>
          <Textarea
            id="message"
            placeholder="Any specific questions or information you'd like to share?"
            className="min-h-[100px]"
          />
        </div>

        <div className="space-y-2">
          <Label>Preferred Contact Method *</Label>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="contact-email" />
              <Label htmlFor="contact-email">Email</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="contact-phone" />
              <Label htmlFor="contact-phone">Phone</Label>
            </div>
          </div>
        </div>

        <Button type="submit" className="w-full">
          Submit Request
        </Button>
      </form>
    </div>
  )
}

export default Sales
