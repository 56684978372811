import { Trash2 } from 'lucide-react'
import { FC, Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

import { FormData } from './'

const HeadersForm: FC = () => {
  const form = useFormContext<FormData>()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'headers',
  })

  return (
    <div className="pt-2">
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <div className="flex gap-2 py-2">
            <Input {...form.register(`headers.${index}.key`)} placeholder="Header" className="w-1/3" />
            <Input {...form.register(`headers.${index}.value`)} placeholder="Value" className="w-2/3" />
            <Button size="icon" type="button" variant="outline" onClick={() => remove(index)}>
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
          {form.formState.errors.headers?.[index]?.key && (
            <p className="mt-1 text-red-500 text-sm">{form.formState.errors.headers[index].key.message}</p>
          )}
          {form.formState.errors.headers?.[index]?.value && (
            <p className="mt-1 text-red-500 text-sm">{form.formState.errors.headers[index].value.message}</p>
          )}
        </Fragment>
      ))}
      {fields.length < 3 && (
        <Button
          type="button"
          variant="outline"
          size="sm"
          className="text-muted-foreground"
          onClick={() => append({ key: '', value: '' })}
        >
          Add a header
        </Button>
      )}
    </div>
  )
}

export default HeadersForm
