import asyncThunk from '@/lib/asyncThunk'
import { LoggingCredentials } from '@/thunks/logging/upsert'

type Response = {
  wasSuccessful: boolean
}

export const testLogging = asyncThunk<LoggingCredentials, Response>({
  name: 'logging/test',
  method: 'POST',
  uri: '/logging/test',
})
