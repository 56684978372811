import asyncThunk from '@/lib/asyncThunk'

type Request = {
  phoneNumber: string
  url: string
  method: 'GET' | 'POST'
  headers: { key: string; value: string }[]
  customerPath: string
  customerId: string
  titleTemplate: string
  descriptionTemplate: string
}

type Response = {
  customers: Customer[]
}

type Customer = {
  id: string
  title: string
  description: string
}

export const testCustomerLookup = asyncThunk<Request, Response>({
  name: 'customer-lookup/test',
  method: 'POST',
  uri: '/customer-lookup/test',
})
