import { Cable } from 'lucide-react'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import AWSRegionSelect from '@/components/aws-region-select'
import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { AWSRegion, awsRegions } from '@/lib/awsRegions'
import { FormProps } from '@/pages/settings/logging'
import IAMPolicy from '@/pages/settings/logging/cloudwatch/iam-policy'
import TestCloudwatch from '@/pages/settings/logging/cloudwatch/test'

const regions = awsRegions.map(({ value }) => value) as [AWSRegion, ...AWSRegion[]]

const schema = z
  .object({
    accessKeyId: z.string().min(1, { message: 'AWS Access Key ID is required' }),
    secretAccessKey: z.string().min(1, { message: 'AWS Secret Access Key is required' }),
    region: z.enum(regions, { message: 'AWS Region is required' }),
    logGroup: z.string(),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = FormProps<'cloudwatch'>

const CloudwatchForm: FC<Props> = ({ onSetup, setupTimer, onTest, testTimer }) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      accessKeyId: '',
      secretAccessKey: '',
      region: 'us-east-1',
      logGroup: '',
    },
    mode: 'onChange',
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSetup)} className="mt-6 space-y-4">
        <Field<FormData> label="AWS Access Key ID" name="accessKeyId" placeholder="Enter your AWS Access Key ID" />

        <Field<FormData>
          label="AWS Secret Access Key"
          name="secretAccessKey"
          placeholder="Enter your AWS Secret Access Key"
        />

        <Field<FormData> label="Log Group" name="logGroup" placeholder="/journeyid/logs" />

        <Field<FormData> label="AWS Region" name="region">
          {({ value, onChange }) => <AWSRegionSelect value={value as AWSRegion} onChange={onChange} />}
        </Field>

        <div className="flex pt-2 space-x-4">
          <TestCloudwatch timer={testTimer} onTest={() => onTest(form.getValues())} />
          <IAMPolicy region={form.watch('region')} logGroupName={form.watch('logGroup')} />
        </div>
        <LoadingButton timer={setupTimer} type="submit" className="w-full">
          <Cable className="w-4 h-4 mr-2" />
          {setupTimer.isLoading ? 'Connecting...' : 'Connect AWS Cloudwatch'}
        </LoadingButton>
      </form>
    </FormProvider>
  )
}

export default CloudwatchForm
