import { FC } from 'react'

import { useAppSelector } from '@/store'

// const items = (app: App) => [
//   {
//     label: 'Dashboard',
//     path: r.apps[app].root,
//   },
//   {
//     label: 'Pricing Plans',
//     path: r.apps[app].pricing,
//   },
// ]

const InactiveAppNav: FC = () => {
  const { app } = useAppSelector()

  if (!app) return null

  return (
    <nav className="flex flex-1 gap-1.5">
      {/* {items(app).map(item => (
        <Link
          key={item.path}
          to={item.path}
          className={clsx(
            'px-5 py-2 rounded-lg font-light text-sm whitespace-nowrap transition-all duration-200',
            isActive(pathname, item.path)
              ? 'text-foreground bg-muted/50'
              : 'text-muted-foreground hover:text-foreground hover:bg-muted/30'
          )}
        >
          {item.label}
        </Link>
      ))} */}
    </nav>
  )
}

export default InactiveAppNav
