import { useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'

import { configureStore } from '@reduxjs/toolkit'

import { App } from '@/apps'
import { switchApp } from '@/thunks/apps/switch'

import app from './slices/app'
import auth from './slices/auth'
import billing from './slices/billing'

const rootReducer = combineReducers({
  app: app.reducer,
  auth: auth.reducer,
  billing: billing.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  // middleware: getDefaultMiddleware => getDefaultMiddleware({}).concat(thunk),
  devTools: { name: 'Redux Debugger' },
})

const token = new URLSearchParams(window.location.search).get('token')
if (token) {
  store.dispatch(auth.actions.setJWT(token))
}

export { store }

export type AppState = ReturnType<typeof rootReducer>
type AppDispatch = typeof store.dispatch

export function useAppDispatch(): AppDispatch {
  return useDispatch()
}

export function useAppSelector() {
  const dispatch = useAppDispatch()
  const state = useSelector((state: AppState) => state.app)
  const setApp = (app: App) => dispatch(switchApp(app))
  return { ...state, setApp }
}

export function useBillingSelector() {
  return useSelector((state: AppState) => state.billing)
}

export function useAuthSelector() {
  return useSelector((state: AppState) => state.auth)
}
