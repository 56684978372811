import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { actions } from '@/slices/auth'
import { useAppDispatch } from '@/store'
import { bootstrapAdmin } from '@/thunks/admins/bootstrap'
import { unghost } from '@/thunks/ghost-mode/unghost'
import { ghost } from '@/watch-tower/thunks/ghost-mode/ghost'

import { useAPI } from './useAPI'
import useClaims from './useClaims'

export default function useGhostMode(accountId?: string) {
  const claims = useClaims()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [becomeGhost, { timer: ghostTimer }] = useAPI(ghost)
  const handleGhost = useCallback(async () => {
    if (!accountId) return
    const [{ token }] = await becomeGhost({ accountId })
    dispatch(actions.setJWT(token))
    dispatch(bootstrapAdmin({}))
    navigate(r.root)
  }, [becomeGhost, accountId, navigate, dispatch])

  const [undoGhost, { timer: unghostTimer }] = useAPI(unghost)
  const handleUnghost = useCallback(async () => {
    const [{ token }] = await undoGhost({})
    dispatch(actions.setJWT(token))
    dispatch(bootstrapAdmin({}))
    navigate(`/watch-tower/accounts/${claims['acc']}`)
  }, [undoGhost, navigate, dispatch, claims])

  const isGhost = !!claims?.ghost
  return [isGhost, { ghost: handleGhost, ghostTimer, unghost: handleUnghost, unghostTimer }] as const
}
