import { Palette, Upload } from 'lucide-react'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import {
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,
} from '@/components/ui/card'
import { ColorPicker } from '@/components/ui/color-picker'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { Branding } from '@/out/branding'
import useUpdateBranding from '@/pages/settings/branding/LogoUploadCard/useUpdateBranding'

type Props = {
  branding: Branding | undefined
}

const LogoUploadCard: FC<Props> = ({ branding }) => {
  const [update, { form, timer }] = useUpdateBranding()

  useEffect(() => {
    if (!branding) return

    form.setValue('name', branding.name)
    form.setValue('logo', branding.logo)
    form.setValue('color', branding.color)
  }, [branding, form])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [options, setOptions] = useState<string[]>([])

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file?.type !== 'image/svg+xml') {
        console.log('unexpected file type:', file?.type)
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        if (!e.target?.result) return

        const svgContent = e.target.result as string
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
        const svgElement = svgDoc.documentElement

        // Remove width and height attributes
        svgElement.removeAttribute('width')
        svgElement.removeAttribute('height')

        // Add preserveAspectRatio attribute
        svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet')

        // Set viewBox if it doesn't exist
        if (!svgElement.getAttribute('viewBox')) {
          const width = svgElement.getAttribute('width') || '100'
          const height = svgElement.getAttribute('height') || '100'
          svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`)
        }

        const updatedSvgContent = new XMLSerializer().serializeToString(svgElement)
        form.setValue('logo', updatedSvgContent)

        const matches = updatedSvgContent
          .match(/#[a-f0-9]{3,6}/gi)
          ?.map(color => color.toUpperCase())
          ?.filter(color => color !== '#000000' && color !== '#FFFFFF' && color !== '#FFF' && color !== '#000')
          .filter((color, index, self) => self.indexOf(color) === index)
        setOptions(matches || [])
      }
      reader.readAsText(file)
    },
    [form]
  )

  const logo = form.watch('logo')

  return (
    <Card className="w-lg">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(update)}>
          <CardHeader>
            <CardTitle className="flex items-center gap-3">
              <Upload className="w-5 h-5 text-primary" />
              Brand Settings
            </CardTitle>
            <CardDescription>
              Customize your organization&apos;s identity including a logo and brand color.
            </CardDescription>
          </CardHeader>

          <Separator />

          <CardContent className="grid gap-8 pt-6">
            <Field name="name" label="Company name" />

            <section>
              <div className="flex items-center justify-between gap-2 mb-4">
                <div>
                  <Label className="text-sm font-medium text-foreground">Logo Upload</Label>
                  <p className="mt-1 text-sm text-muted-foreground">
                    Your logo will be displayed across all agent and customer applications.
                  </p>
                </div>
              </div>

              <div
                onClick={() => fileInputRef.current?.click()}
                className="flex items-center justify-center w-full h-48 overflow-hidden text-center transition-all duration-200 border-2 border-dashed rounded-lg cursor-pointer border-muted hover:border-primary/50 bg-secondary"
              >
                {logo ? (
                  <div className="flex items-center justify-center w-full h-full p-4">
                    <div
                      dangerouslySetInnerHTML={{ __html: logo }}
                      className="w-full h-full flex items-center justify-center [&>svg]:max-w-full [&>svg]:min-h-16 [&>svg]:max-h-24 [&>svg]:w-auto [&>svg]:h-auto"
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-1 text-center text-muted-foreground">
                    <p>Drop your SVG logo here</p>
                    <p className="text-sm">or click to browse</p>
                  </div>
                )}
              </div>

              <Input
                id="logo-upload"
                type="file"
                accept=".svg"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </section>

            <section>
              <div className="flex items-center justify-between gap-2 mb-4">
                <div>
                  <Label className="text-sm font-medium text-foreground">Primary Brand Color</Label>
                  <p className="mt-1 text-sm text-muted-foreground">Sets the main accent color for all apps.</p>
                </div>
                <div className="flex items-center gap-3">
                  <div className="flex items-center justify-center p-1 border rounded-md max-h-9">
                    <ColorPicker
                      value={form.watch('color')}
                      onChange={value => form.setValue('color', value.toUpperCase())}
                      onBlur={form.trigger.bind(null, 'color')}
                      className="border-none w-7 h-7"
                    />
                  </div>
                  <Input
                    placeholder="#000000"
                    {...form.register('color')}
                    value={form.watch('color')}
                    onChange={e => form.setValue('color', e.target.value.toUpperCase())}
                    maxLength={7}
                    className="w-24 font-mono uppercase bg-background"
                  />
                </div>
              </div>

              {options.length > 0 && (
                <div className="p-4 rounded-lg bg-secondary">
                  <p className="mb-3 text-sm text-muted-foreground">Colors detected in logo:</p>
                  <div className="flex flex-wrap gap-2">
                    {options.map((color, index) => (
                      <div
                        key={index}
                        onClick={() => form.setValue('color', color)}
                        className="w-8 h-8 transition-all rounded-md cursor-pointer ring-border ring-1 hover:ring-2 hover:ring-primary hover:scale-105"
                        style={{ backgroundColor: color }}
                        title={color}
                      />
                    ))}
                  </div>
                </div>
              )}
            </section>
          </CardContent>
          <CardFooter className="items-center justify-between">
            <div className="text-xs text-destructive">
              {form.formState.errors.logo?.message || form.formState.errors.color?.message}
            </div>
            <LoadingButton type="submit" timer={timer}>
              <Palette className="w-4 h-4 mr-2" /> Save changes
            </LoadingButton>
          </CardFooter>
        </form>
      </FormProvider>
    </Card>
  )
}

export default LogoUploadCard
