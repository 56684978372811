import { useMemo } from 'react'

import { App, appNames } from '@/apps'
import { Metric } from '@/components/chart'
import useObject from '@/hooks/useObject'
import objectKeys from '@/util/object-keys'
import { statisticsSummary } from '@/watch-tower/thunks/statistics/summary'
import colors from 'tailwindcss/colors'

export default function useMetrics() {
  const [summary] = useObject(statisticsSummary, {})

  const global = useMemo((): Metric | undefined => {
    if (!summary) return

    return {
      id: 'count',
      name: 'Total',
      color: colors.blue[600],
      data: summary.global.map(data => ({
        id: data.date,
        date: data.date,
        count: data.count,
      })),
    }
  }, [summary])

  const apps = useMemo((): Record<App, Metric> | undefined => {
    if (!summary) return

    return objectKeys(summary.apps).reduce(
      (memo, app) => {
        memo[app] = {
          id: 'count',
          name: appNames[app],
          color: colors.gray[300],
          data: summary.apps[app].map(data => ({
            id: data.date,
            date: data.date,
            count: data.count,
          })),
        }

        return memo
      },
      {} as Record<App, Metric>
    )
  }, [summary])

  const paidAccounts = useMemo((): Metric | undefined => {
    if (!summary) return

    return {
      id: 'count',
      name: 'Paid Accounts',
      color: colors.green[600],
      data: summary.paidAccounts.map(data => ({
        id: data.date,
        date: data.date,
        count: data.count,
      })),
    }
  }, [summary])

  return { global, apps, paidAccounts }
}
