import asyncThunk from '@/lib/asyncThunk'

type Request = {
  accountId: string
}

type Response = {
  token: string
}

export const ghost = asyncThunk<Request, Response>({
  name: 'watch-tower/ghost-mode/ghost',
  uri: '/watch-tower/ghost-mode',
  method: 'POST',
})
