import asyncThunk from '@/lib/asyncThunk'

type Request = {
  provider: 'azure' | 'google'
  code: string
}

type Response = {
  token: string
}

export const oauthCallback = asyncThunk<Request, Response>({
  name: 'oauth/callback',
  method: 'POST',
  uri: ({ provider }) => `/oauth/${provider}/callback`,
  body: ({ code }) => ({ code }),
})
