import { Globe } from 'lucide-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import LoadingButton from '@/components/loading-button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { useAPI } from '@/hooks/useAPI'
import { isErrorResponse } from '@/lib/asyncThunk'
import { Branding } from '@/out/branding'
import { updateDomain } from '@/thunks/branding/domain'

type Props = {
  branding: Branding
  onSuccess: (branding: Branding) => void
  onRefresh: () => void
}

const CustomDomainCard: FC<Props> = ({ branding, onSuccess, onRefresh }) => {
  const [domain, setDomain] = useState('')

  const [error, setError] = useState<string>()
  const [setup, { timer }] = useAPI(updateDomain)
  const handleSubmit = useCallback(async () => {
    try {
      setError(undefined)

      const [branding] = await setup({ domain })
      onSuccess(branding)
    } catch (err) {
      if (isErrorResponse(err) && err.code === 'DNS_INVALID') {
        setError("DNS settings are invalid or haven't propagated yet")
      } else {
        setError('An error occurred while verifying the DNS settings')
      }
    }
  }, [setup, domain, onSuccess])

  const [countdown, setCountdown] = useState(15)
  useEffect(() => {
    if (!branding.domain || branding.domainIsVerified) return

    const t = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          onRefresh()
          return 15
        }
        return prev - 1
      })
    }, 1_000)

    return () => clearInterval(t)
  }, [branding, onRefresh])

  return (
    <Card>
      <CardHeader className="pb-8">
        <CardTitle className="flex items-center gap-3">
          <div className="bg-primary/5 p-2 rounded-lg">
            <Globe className="w-5 h-5 text-primary" strokeWidth={1.5} />
          </div>
          <span className="text-xl">Branded Domain</span>
        </CardTitle>
        <CardDescription className="leading-relaxed">
          Configure a custom domain for your customers&lsquo; branded experience
        </CardDescription>
      </CardHeader>

      <Separator />

      <CardContent className="space-y-8 pt-8">
        {branding.domain ? (
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              {branding.domainIsVerified ? (
                <div className="flex flex-col">
                  <div className="flex items-center gap-4 w-full">
                    <div className="flex flex-1 items-center gap-3 border-green-100 bg-green-50/80 px-6 py-3 border rounded-xl text-green-600/90">
                      <div className="bg-green-500 rounded-full w-2.5 h-2.5 animate-pulse"></div>
                      <span className="font-medium font-mono">{branding.domain}</span>
                      <span className="text-green-600/75">is verified and active</span>
                    </div>
                  </div>
                  <p className="mt-4 text-muted-foreground text-sm">
                    Your domain will now appear in the links sent to your customers via SMS as well as in your
                    customers&apos; browser.
                  </p>
                </div>
              ) : (
                <div className="space-y-2 bg-blue-50/80 px-6 py-4 border border-blue-100 rounded-xl w-full">
                  <div className="flex items-center gap-2">
                    <div className="border-2 border-t-transparent border-blue-500 rounded-full w-4 h-4 animate-spin"></div>
                    <p className="font-medium text-blue-700">Verifying {branding.domain}...</p>
                  </div>
                  <p className="text-blue-600/75 text-sm">
                    Next check in {countdown} seconds. This generally takes 30-60 seconds.
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <section>
              <div>
                <Label className="inline-block bg-primary/5 px-4 py-2 rounded-lg font-semibold text-base text-primary">
                  Step 1: Configure DNS
                </Label>
              </div>

              <div className="border-2 bg-zinc-50/50 shadow-sm hover:shadow-md my-6 p-8 rounded-xl transition-all duration-200">
                <div className="flex justify-between items-center">
                  <div className="pr-8">
                    <p className="mb-2 font-medium text-muted-foreground/80 text-xs uppercase tracking-wider">Type</p>
                    <p className="font-medium font-mono text-primary/90">CNAME</p>
                  </div>
                  <div className="flex-1">
                    <p className="mb-2 font-medium text-muted-foreground/80 text-xs uppercase tracking-wider">Value</p>
                    <p className="font-medium font-mono text-primary/90">
                      {import.meta.env.VITE_CUSTOMER_PROXY_DOMAIN}
                    </p>
                  </div>
                </div>
              </div>

              <section>
                <div className="space-y-1.5 mb-4">
                  <Label className="inline-block bg-primary/5 px-4 py-2 rounded-lg font-semibold text-base text-primary">
                    Step 2: Enter Domain
                  </Label>
                </div>

                <Input
                  id="domain"
                  placeholder="cx.example.com"
                  value={domain}
                  autoComplete="one-time-code"
                  onChange={e => setDomain(e.target.value)}
                  required
                  className="shadow-sm hover:shadow-md px-4 py-6 text-lg transition-all duration-200"
                />
              </section>

              <div className="space-y-4 mt-6">
                {error && (
                  <div className="flex items-center gap-2 bg-red-50/80 px-6 py-3 border border-red-100 rounded-xl text-red-500">
                    <div className="text-lg">⚠️</div>
                    <span>{error}</span>
                  </div>
                )}
                <div className="flex items-center gap-4">
                  <LoadingButton
                    timer={timer}
                    onClick={handleSubmit}
                    disabled={!domain.match(/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/)}
                    className="bg-primary shadow-sm hover:shadow-md px-6 py-3 rounded-xl transition-all duration-200"
                  >
                    Verify DNS settings
                  </LoadingButton>

                  <div className="flex items-center gap-2 w-full">
                    <p className="border-amber-100 bg-amber-50/80 px-6 py-3 border rounded-xl w-full text-amber-600">
                      <span className="mr-2">⚠️</span>
                      DNS changes can take up to 48 hours to propagate globally
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </CardContent>

      <CardFooter className="py-6">
        <div className="text-muted-foreground/80 text-sm">
          Need help?{' '}
          <Link
            to={r.support.root}
            className="font-medium text-primary hover:text-primary/80 hover:underline transition-colors"
          >
            Contact our support team →
          </Link>
        </div>
      </CardFooter>
    </Card>
  )
}

export default CustomDomainCard
