import { useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { Logging, LoggingDestination } from '@/out/logging'
import { upsertLogging } from '@/thunks/logging/upsert'

type SetupFunc<T extends LoggingDestination> = (destination: T, config: NonUndefined<Logging[T]>) => void

export default function useLoggingUpsert<T extends LoggingDestination>(onSetup: SetupFunc<T>) {
  const [upsert, { timer }] = useAPI(upsertLogging)

  const handleUpsert = useCallback(
    async (destination: T, config: NonUndefined<Logging[T]>): Promise<Logging> => {
      timer.setId(destination)

      const [res] = await upsert({ destination, [destination]: config })

      toast.success(`${destination} has been configured`)

      const conf = res[destination] as NonUndefined<Logging[T]>
      onSetup(destination, conf)

      return res
    },
    [timer, upsert, onSetup]
  )

  return [handleUpsert, { timer }] as const
}
