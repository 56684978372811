import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import useActionTimer from '@/hooks/useActionTimer'
import { useAPI } from '@/hooks/useAPI'
import { APIError, isErrorResponse } from '@/lib/asyncThunk'
import { ssoAuthorize } from '@/thunks/sso/authorize'

const FormSchema = z.object({
  email: z.string().email('Please provide a valid email address'),
})

type FormData = z.infer<typeof FormSchema>

export default function useSSO() {
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  })

  const timer = useActionTimer()
  const [auth] = useAPI(ssoAuthorize)

  const authorize = useCallback(
    async (data: FormData) => {
      if (!data.email) return

      try {
        timer.start()
        const [{ url }] = await auth(data)
        window.location.href = url
      } catch (error) {
        timer.failed()
        if (isErrorResponse(error)) {
          if (error.code === 'SSO_NOT_CONFIGURED') {
            form.setError('email', { message: 'SSO is not configured for this email address' })
          } else {
            form.setError('email', { message: error.error })
          }
        } else {
          form.setError('email', { message: (error as APIError<FormData>).error ?? 'An unknown error occurred' })
        }
      }
    },
    [auth, form, timer]
  )

  return { form, authorize, timer }
}
