import { FC, useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import { ActionTimer, idleTimer } from '@/hooks/useActionTimer'
import useObject from '@/hooks/useObject'
import { Logging, LoggingDestination } from '@/out/logging'
import CSV from '@/pages/settings/logging/csv'
import SFTP from '@/pages/settings/logging/sftp'
import useLoggingRemove from '@/pages/settings/logging/useLoggingRemove'
import useLoggingTest from '@/pages/settings/logging/useLoggingTest'
import useLoggingUpsert from '@/pages/settings/logging/useLoggingUpsert'
import { retrieveLogging } from '@/thunks/logging/retrieve'
import { LoggingCredentials } from '@/thunks/logging/upsert'

import Cloudwatch from './cloudwatch'
import Datadog from './datadog'
import Sumologic from './sumologic'

export type LoggingProps<T extends LoggingDestination> = {
  onSetup: (destination: T, config: NonUndefined<LoggingCredentials[T]>) => Promise<Logging>
  setupTimer: ActionTimer
  onTest: (destination: T, config: NonUndefined<LoggingCredentials[T]>) => Promise<boolean>
  testTimer: ActionTimer
  onRemove: (destination: LoggingDestination) => void
  removeTimer: ActionTimer
}

export type FormProps<T extends LoggingDestination> = {
  onSetup: (config: NonUndefined<LoggingCredentials[T]>) => void
  setupTimer: ActionTimer
  onTest: (config: NonUndefined<LoggingCredentials[T]>) => Promise<boolean>
  testTimer: ActionTimer
}

const SettingsLogging: FC = () => {
  const [configuration, { patch, wasLoaded }] = useObject(retrieveLogging, {})

  const [upsert, { timer: setupTimer }] = useLoggingUpsert((destination, config) => patch({ [destination]: config }))
  const [test, { timer: testTimer }] = useLoggingTest()
  const [remove, { timer: removeTimer }] = useLoggingRemove(destination => patch({ [destination]: undefined }))

  const props = useCallback(
    <T extends LoggingDestination>(destination: T): LoggingProps<T> => ({
      onSetup: upsert,
      setupTimer: setupTimer.id === destination ? setupTimer : idleTimer,
      onTest: test,
      testTimer: testTimer.id === destination ? testTimer : idleTimer,
      onRemove: remove,
      removeTimer: removeTimer.id === destination ? removeTimer : idleTimer,
    }),
    [upsert, test, remove, setupTimer, testTimer, removeTimer]
  )

  return (
    <SettingsLayout>
      <Settings.Title title="Logging" />
      <Settings.Description>
        Configure external data store destinations by linking your account to third-party services. Use the settings
        below to connect, manage, and customize integrations for efficient data storage and retrieval.
      </Settings.Description>
      {wasLoaded && (
        <div className={'grid grid-cols-1 gap-6 lg:grid-cols-2 auto-rows-min'}>
          <Datadog configuration={configuration?.datadog} {...props('datadog')} />
          <Cloudwatch configuration={configuration?.cloudwatch} {...props('cloudwatch')} />
          <Sumologic configuration={configuration?.sumologic} {...props('sumologic')} />
          <CSV />
          <SFTP configuration={configuration?.sftp} {...props('sftp')} />
        </div>
      )}
    </SettingsLayout>
  )
}

export default SettingsLogging
