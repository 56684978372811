import * as Sentry from '@sentry/react'

import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

Sentry.init({
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: 'https://6b7495979e710f2fc5b94a66f650d42e@o997326.ingest.us.sentry.io/4508576551469056',
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
  tracesSampleRate: 1.0,
})
