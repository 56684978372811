import clsx from 'clsx'
import { Check, Copy, ExternalLink } from 'lucide-react'
import { FC } from 'react'

import zoomSDK from '@zoom/appssdk'

import SettingsLayout from '@/components/layout/settings-layout'
import SettingsTitle from '@/components/settings-title'
import useActionTimer from '@/hooks/useActionTimer'
import useClaims from '@/hooks/useClaims'
import useObject from '@/hooks/useObject'
import { accountDeveloperSettings } from '@/thunks/accounts/developer'

import APIKeysCard from './APIKeysCard'
import WebhookSecretCard from './WebhookSecret'

const Developers: FC = () => {
  const copyTimer = useActionTimer()
  const [settings] = useObject(accountDeveloperSettings, {})
  const claims = useClaims()

  if (!settings) return null

  const Icon = copyTimer.didSucceed ? Check : Copy

  return (
    <SettingsLayout>
      <SettingsTitle title="Developers" />
      <div className="space-y-8">
        <APIKeysCard {...settings} />
        <WebhookSecretCard {...settings} />
      </div>
      <div className="relative max-w-lg">
        <div className="flex justify-between items-center bg-background mt-4 px-3 py-2 border rounded-md text-sm">
          <span className="text-muted-foreground">Account ID:</span>
          <div className="flex items-center space-x-2">
            <code className="font-mono text-sm">{claims['acc']}</code>
            <button
              onClick={() => {
                navigator.clipboard.writeText(claims['acc'])
                copyTimer.succeeded()
              }}
              className="hover:bg-muted p-1 rounded-md transition-colors"
            >
              <Icon
                className={clsx('w-3 h-3', {
                  'text-muted-foreground': !copyTimer.didSucceed,
                  'text-green-600': copyTimer.didSucceed,
                })}
              />
            </button>
          </div>
        </div>
      </div>
      {window.isZoom && (
        <div className="flex justify-end space-x-4 pr-2 max-w-lg text-muted-foreground text-xs">
          <div
            className="flex space-x-2 hover:text-foreground cursor-pointer group"
            onClick={() =>
              zoomSDK.openUrl({
                url: window.location.href + '?token=' + localStorage.getItem('token'),
              })
            }
          >
            <span>Open in browser</span>
            <ExternalLink className="group-hover:text-muted-foreground w-4 h-4 text-muted-foreground/50" />
          </div>
        </div>
      )}
    </SettingsLayout>
  )
}

export default Developers
