import { createElement, FC } from 'react'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { AWSCountry, AWSRegion, awsRegions } from '@/lib/awsRegions'
import {
  AE,
  AU,
  BH,
  BR,
  CA,
  CH,
  DE,
  ES,
  FlagComponent,
  FR,
  GB,
  HK,
  ID,
  IE,
  IL,
  IN,
  IT,
  JP,
  KR,
  MY,
  SE,
  SG,
  US,
  ZA,
} from 'country-flag-icons/react/3x2'

const icons: Record<AWSCountry, FlagComponent> = {
  // eslint-disable-next-line prettier/prettier
  US, CA, ZA, HK, IN, ID, MY, AU, JP, KR, SG, IE, GB, DE, IT, FR, ES, SE, CH, IL, BH, AE, BR,
}

type Props = {
  value: AWSRegion | undefined
  onChange: (region: AWSRegion) => void
}

const AWSRegionSelect: FC<Props> = ({ value, onChange }) => {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger id="application" className="items-start w-full">
        <SelectValue placeholder="Select a region" />
      </SelectTrigger>
      <SelectContent>
        {awsRegions.map(region => (
          <SelectItem key={region.value} value={region.value} className="cursor-pointer">
            <div className="flex items-start gap-3 text-muted-foreground">
              {createElement(icons[region.country], { className: 'w-5 h-5' })}
              <p className="font-medium text-foreground">
                {region.label} <span className="ml-2 text-muted-foreground">{region.value}</span>
              </p>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default AWSRegionSelect
