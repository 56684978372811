import clsx from 'clsx'
import { FC } from 'react'
import { Link, useLocation } from 'react-router'

import { appNavigation } from '@/app-navigation'
import { useAppSelector } from '@/store'

function isActive(location: string, path: string) {
  if (location === path) return true
  if (!path.match(/\/.+\//)) return false
  return location.startsWith(path)
}

const DesktopNav: FC = () => {
  const { app } = useAppSelector()
  const { pathname } = useLocation()

  return (
    <nav className="flex flex-1 gap-2">
      {app &&
        appNavigation[app].map(item => (
          <Link
            key={item.title}
            to={item.path}
            className={clsx(
              'px-5 py-2 rounded-lg text-sm whitespace-nowrap transition-all duration-200',
              isActive(pathname, item.path)
                ? 'text-foreground bg-muted/50'
                : 'text-muted-foreground hover:text-foreground hover:bg-muted/30'
            )}
          >
            {item.title}
          </Link>
        ))}
    </nav>
  )
}

export default DesktopNav
