import { FC, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'
import r from 'routes'

import LayoutHeader from '@/components/layout/header'
import LayoutMain from '@/components/layout/main'
import Sales from '@/pages/sales'
import Settings from '@/pages/settings'
import Support from '@/pages/support'
import SupportTicket from '@/pages/support/ticket'

import AuthenticatedApp from './components/authenticated-app'
import EmbeddedApp from './components/embedded-app'
import Home from './pages/home'
import DataProcessingAddendum from './pages/legal/data-processing-addendum'
import Resources from './pages/resources'
import WatchTower from './watch-tower/pages'

const OneTimePasscode = lazy(() => import('@/pages/apps/one-time-passcode'))
const Passkeys = lazy(() => import('@/pages/apps/passkeys'))
const DocumentSignature = lazy(() => import('@/pages/apps/document-signature'))
const EForm = lazy(() => import('@/pages/apps/e-form'))
const FileUpload = lazy(() => import('@/pages/apps/file-upload'))
const Payments = lazy(() => import('@/pages/apps/payments'))

const App: FC = () => {
  return (
    <div className="flex flex-col w-full min-h-screen bg-background text-balance">
      <Routes>
        <Route path={r.legal.dataProcessingAddendum} Component={DataProcessingAddendum} />
        <Route
          path="/*"
          element={
            <EmbeddedApp>
              <AuthenticatedApp>
                <Routes>
                  <Route path={r.watchTower.root + '/*'} Component={WatchTower} />
                  <Route path={r.legal.dataProcessingAddendum} Component={DataProcessingAddendum} />
                  <Route
                    path="/*"
                    element={
                      <>
                        <LayoutHeader />
                        <LayoutMain>
                          <Suspense fallback={<></>}>
                            <Routes>
                              <Route path={r.root} Component={Home} />
                              <Route path={r.settings.root + '/*'} Component={Settings} />
                              <Route path={r.support.root} Component={Support} />
                              <Route path={r.support.ticket} Component={SupportTicket} />
                              <Route path={r.sales.root} Component={Sales} />
                              <Route path={r.resources.root} Component={Resources} />
                              <Route path={r.apps['document-signature'].root + '/*'} Component={DocumentSignature} />
                              <Route path={r.apps['file-upload'].root + '/*'} Component={FileUpload} />
                              <Route path={r.apps['one-time-passcode'].root + '/*'} Component={OneTimePasscode} />
                              <Route path={r.apps['passkeys'].root + '/*'} Component={Passkeys} />
                              <Route path={r.apps['payments'].root + '/*'} Component={Payments} />
                              <Route path={r.apps['e-form'].root + '/*'} Component={EForm} />
                            </Routes>
                          </Suspense>
                        </LayoutMain>
                      </>
                    }
                  />
                </Routes>
              </AuthenticatedApp>
            </EmbeddedApp>
          }
        />
      </Routes>
    </div>
  )
}

export default App
