import { Cable } from 'lucide-react'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FormProps } from '@/pages/settings/logging'
import TestDatadog from '@/pages/settings/logging/datadog/test'

const schema = z
  .object({
    apiKey: z.string().min(1, { message: 'API Key is required' }),
    site: z.string().min(1, { message: 'Datadog Site is required' }),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = FormProps<'datadog'>

const DatadogForm: FC<Props> = ({ onSetup, setupTimer, onTest, testTimer }) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      apiKey: '',
      site: 'us1',
    },
    mode: 'onChange',
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSetup)} className="mt-6 space-y-4">
        <Field<FormData> label="API Key" name="apiKey" placeholder="Enter your Datadog API Key" />

        <Field<FormData> label="Datadog Site" name="site">
          {({ value, onChange }) => (
            <Select value={value} onValueChange={onChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select Datadog site" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="us1">US1 (app.datadoghq.com)</SelectItem>
                <SelectItem value="us3">US3 (us3.datadoghq.com)</SelectItem>
                <SelectItem value="us5">US5 (us5.datadoghq.com)</SelectItem>
                <SelectItem value="eu">EU (app.datadoghq.eu)</SelectItem>
                <SelectItem value="ap1">JP (ap1.datadoghq.com)</SelectItem>
              </SelectContent>
            </Select>
          )}
        </Field>

        <div className="flex pt-2 space-x-4">
          <TestDatadog timer={testTimer} onTest={() => onTest(form.getValues())} />
        </div>
        <div>
          <LoadingButton timer={setupTimer} type="submit" className="w-full">
            <Cable className="w-4 h-4 mr-2" />
            Connect Datadog
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}

export default DatadogForm
