import { FC, ReactNode } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import r from 'routes'

import SignIn from '@/pages/authentication/sign-in'
import SSOCallback from '@/pages/authentication/sign-in/SSOCallback'
import SignUp from '@/pages/authentication/sign-up'
import { useAuthSelector } from '@/store'

import useZoom from './useZoom'

type Props = {
  children: ReactNode
}

const EmbeddedApp: FC<Props> = ({ children }) => {
  const { token } = useAuthSelector()

  useZoom()

  if (!token) {
    return window.isStandalone ? (
      <Routes>
        <Route path={r.root} Component={SignIn} />
        <Route path={r.authentication.signUp} Component={SignUp} />
        <Route path={r.sso.callback} Component={SSOCallback} />
        <Route
          path="*"
          Component={() => (
            <Navigate to={r.root + (window.location.pathname === r.root ? window.location.search : '')} replace />
          )}
        />
      </Routes>
    ) : null
  }

  return <>{children}</>
}

export default EmbeddedApp
