import asyncThunk from '@/lib/asyncThunk'

type Request = {
  id: string
}

export type Response = {
  id: string
  archivedAt: string
}

export const archiveAdmin = asyncThunk<Request, Response>({
  name: 'admins/archive',
  method: 'DELETE',
  uri: ({ id }) => `/admins/${id}`,
})
