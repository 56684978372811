import { FC } from 'react'

import LoadingButton from '@/components/loading-button'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Invitation } from '@/out/invitation'
import useRevokeInvitation from '@/pages/settings/admin-management/useRevokeInvitation'

type Props = {
  invitation: Invitation
  onRevoke: (id: string) => void
}

const RevokeInvitationDialog: FC<Props> = ({ invitation, onRevoke }) => {
  const [revoke, { timer }] = useRevokeInvitation(onRevoke)

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="secondary" size="sm" className="hover:bg-destructive hover:text-background">
          Revoke
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            After revoking the invitation, the user will need to be invited again if you wish to give them access.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <LoadingButton timer={timer} variant="destructive" onClick={() => revoke(invitation)}>
            Revoke
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RevokeInvitationDialog
