import { Mail } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import GoogleIcon from '@/assets/logos/google.svg?react'
import JourneySecondary from '@/assets/logos/journey-secondary.svg?react'
import MicrosoftIcon from '@/assets/logos/microsoft.svg?react'
import LoadingButton from '@/components/loading-button'
import { Input } from '@/components/ui/input'
import { useQueryParams } from '@/hooks/useQueryParams'

import GoogleButton from './GoogleButton'
import MicrosoftButton from './MicrosoftButton'
import useSSO from './useSSO'

type QueryParams = {
  expired?: string
}

const SignIn = () => {
  const { form, authorize, timer } = useSSO()
  const params = useQueryParams<QueryParams>()
  const [error, setError] = useState<string>(() => {
    return params.expired ? 'Your session has expired. Please sign in again.' : ''
  })

  return (
    <div className="relative flex w-full min-h-screen p-4 bg-secondary/50">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div
          className="absolute inset-0 -z-10"
          style={{
            background: 'radial-gradient(600px circle at center, transparent, rgba(0,42,78,0.05))',
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            background: `
              linear-gradient(90deg, rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
              linear-gradient(rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px
            `,
            maskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
            WebkitMaskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
          }}
        />
      </div>
      <div className="flex items-center justify-center flex-1">
        <div className="relative w-full max-w-sm p-8 space-y-4 overflow-hidden text-center bg-white border-2 border-white shadow-md rounded-xl">
          <div className="-top-20 left-1/2 absolute w-[200%] h-80 -translate-x-1/2 overflow-hidden">
            <div className="z-10 absolute inset-0 bg-gradient-to-b from-[#002A4E]/5 to-transparent" />
            <div
              className="absolute inset-0"
              style={{
                background: `
        linear-gradient(90deg, rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
        linear-gradient(rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px
      `,
                maskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
                WebkitMaskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
              }}
            />
          </div>

          <div className="relative z-30 space-y-4">
            <JourneySecondary className="w-32 h-12 mx-auto mb-4" />
            <h1 className="text-2xl font-semibold">Sign In</h1>
            <p className="text-sm text-muted-foreground">Choose your authentication method</p>
          </div>

          {error && <div className="relative z-30 text-sm text-center text-destructive">{error}</div>}

          <div className="relative z-30 grid grid-cols-2 gap-3">
            <GoogleButton onError={setError}>
              <GoogleIcon className="mr-2" />
              Google
            </GoogleButton>
            <MicrosoftButton>
              <MicrosoftIcon className="mr-2" />
              Microsoft
            </MicrosoftButton>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t border-border" />
            </div>
            <div className="relative flex justify-center text-xs">
              <span className="px-2 bg-background text-muted-foreground">Or continue with</span>
            </div>
          </div>

          <form onSubmit={form.handleSubmit(authorize)} className="relative z-30 space-y-4">
            <div className="space-y-1 text-left">
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <div className="relative">
                <Mail className="absolute w-4 h-4 -translate-y-1/2 top-1/2 left-3 text-muted-foreground" />
                <Input
                  {...form.register('email', {
                    onChange: () => form.clearErrors('email'),
                  })}
                  id="email"
                  placeholder="hi@journeyid.com"
                  className={`h-10 pl-9 ${form.formState.errors.email ? 'border-destructive focus-visible:ring-destructive' : ''
                    }`}
                />
              </div>
              {form.formState.errors.email && (
                <p className="text-sm font-light text-center text-destructive">{form.formState.errors.email.message}</p>
              )}
            </div>

            <LoadingButton size="lg" timer={timer} type="submit" className="w-full">
              Sign in using SSO
            </LoadingButton>
          </form>

          <p className="relative z-30 text-xs text-center text-muted-foreground">
            Don&apos;t have an account?{' '}
            <Link to={r.authentication.signUp} className="text-primary hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignIn
