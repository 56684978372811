import asyncThunk from '@/lib/asyncThunk'
import { Branding } from '@/out/branding'

export type Request = {
  logo: string | undefined
  color: string
}

export const updateBranding = asyncThunk<Request, Branding>({
  name: 'branding/update',
  method: 'PUT',
  uri: '/branding',
})
