import { FC } from 'react'

import { Logging } from '@/out/logging'
import { LoggingProps } from '@/pages/settings/logging'

type Props = LoggingProps<'sftp'> & {
  configuration: Logging['sftp']
}

const SFTP: FC<Props> = () => {
  return null

  // return (
  //   <SettingsCard<'logging'>
  //     title="SFTP"
  //     description="Set up SFTP to send logs to your server. Process, search, and analyze your logs in your own platform. Logs for the previous day will be available by 2am UTC."
  //     icon={<ServerIcon className="w-6 h-6" />}
  //     configuration={configuration}
  //     onRemove={() => onRemove('sftp')}
  //     removeTimer={removeTimer}
  //   >
  //     {configuration && (
  //       <SFTPDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('sftp')} />
  //     )}
  //   </SettingsCard>
  // )
}

export default SFTP
