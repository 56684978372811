export type AWSRegion = (typeof awsRegions)[number]['value']

export type AWSCountry = (typeof awsRegions)[number]['country']

export const awsRegions = [
  { label: 'US East (N. Virginia)', value: 'us-east-1', country: 'US' },
  { label: 'US East (Ohio)', value: 'us-east-2', country: 'US' },
  { label: 'US West (N. California)', value: 'us-west-1', country: 'US' },
  { label: 'US West (Oregon)', value: 'us-west-2', country: 'US' },
  { label: 'Africa (Cape Town)', value: 'af-south-1', country: 'ZA' },
  { label: 'Asia Pacific (Hong Kong)', value: 'ap-east-1', country: 'HK' },
  { label: 'Asia Pacific (Hyderabad)', value: 'ap-south-2', country: 'IN' },
  { label: 'Asia Pacific (Jakarta)', value: 'ap-southeast-3', country: 'ID' },
  { label: 'Asia Pacific (Malaysia)', value: 'ap-southeast-5', country: 'MY' },
  { label: 'Asia Pacific (Melbourne)', value: 'ap-southeast-4', country: 'AU' },
  { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1', country: 'IN' },
  { label: 'Asia Pacific (Osaka)', value: 'ap-northeast-3', country: 'JP' },
  { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2', country: 'KR' },
  { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1', country: 'SG' },
  { label: 'Asia Pacific (Sydney)', value: 'ap-southeast-2', country: 'AU' },
  { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1', country: 'JP' },
  { label: 'Canada (Central)', value: 'ca-central-1', country: 'CA' },
  { label: 'Canada (Calgary)', value: 'ca-west-1', country: 'CA' },
  { label: 'Europe (Frankfurt)', value: 'eu-central-1', country: 'DE' },
  { label: 'Europe (Ireland)', value: 'eu-west-1', country: 'IE' },
  { label: 'Europe (London)', value: 'eu-west-2', country: 'GB' },
  { label: 'Europe (Milan)', value: 'eu-south-1', country: 'IT' },
  { label: 'Europe (Paris)', value: 'eu-west-3', country: 'FR' },
  { label: 'Europe (Spain)', value: 'eu-south-2', country: 'ES' },
  { label: 'Europe (Stockholm)', value: 'eu-north-1', country: 'SE' },
  { label: 'Europe (Zurich)', value: 'eu-central-2', country: 'CH' },
  { label: 'Israel (Tel Aviv)', value: 'il-central-1', country: 'IL' },
  { label: 'Middle East (Bahrain)', value: 'me-south-1', country: 'BH' },
  { label: 'Middle East (UAE)', value: 'me-central-1', country: 'AE' },
  { label: 'South America (São Paulo)', value: 'sa-east-1', country: 'BR' },
] as const
