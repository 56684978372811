import { MessageSquare, Ticket } from 'lucide-react'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { Button } from '@/components/ui/button'
import {
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle,
} from '@/components/ui/card'

const Support: FC = () => {
  const navigate = useNavigate()

  return (
    <div className="max-w-3xl px-4 py-12 mx-auto">
      <header className="mb-12 text-center">
        <h1 className="mb-4 text-4xl font-bold">How can we help you?</h1>
        <p className="text-xl text-muted-foreground">Choose your preferred support option below</p>
      </header>

      <div className="grid gap-8 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <MessageSquare className="w-6 h-6" />
              Live Chat
            </CardTitle>
            <CardDescription>Get instant help from our support team</CardDescription>
          </CardHeader>
          <CardContent>
            <p>
              Connect with a support representative in real-time for quick assistance with your questions or issues.
            </p>
          </CardContent>
          <CardFooter>
            <Button className="w-full">Start Chat</Button>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Ticket className="w-6 h-6" />
              Submit a Ticket
            </CardTitle>
            <CardDescription>Create a support ticket for assistance</CardDescription>
          </CardHeader>
          <CardContent>
            <p>
              Submit a ticket for more complex issues or if you prefer a documented conversation with our support team.
            </p>
          </CardContent>
          <CardFooter>
            <Button className="w-full" onClick={() => navigate(r.support.ticket)}>
              Create Ticket
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export default Support
