import asyncThunk from '@/lib/asyncThunk'
import { SMSConfiguration } from '@/out/sms'

export type Request = {
  provider: 'twilio'

  twilio: {
    accountSid: string
    authToken: string
    phoneNumber: string
  }
}

export const upsertSMS = asyncThunk<Request, SMSConfiguration>({
  name: 'sms/upsert',
  method: 'POST',
  uri: '/sms',
})
