import { FC } from 'react'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import useObject from '@/hooks/useObject'
import CustomDomainCard from '@/pages/settings/branding/CustomDomainCard'
import LogoUploadCard from '@/pages/settings/branding/LogoUploadCard'
import { retrieveBranding } from '@/thunks/branding/retrieve'
import fadeIn from '@/util/fadeIn'

const SettingsBranding: FC = () => {
  const [branding, { set, reload }] = useObject(retrieveBranding, {})

  return (
    <SettingsLayout>
      <Settings.Title title="Branding" />

      {branding && (
        <div className={fadeIn(true, 'flex flex-col items-start space-y-8')}>
          <div className="w-full max-w-lg min-w-0">
            <LogoUploadCard branding={branding} />
          </div>
          <div className="w-full max-w-lg min-w-0">
            <CustomDomainCard branding={branding} onSuccess={set} onRefresh={reload} />
          </div>
        </div>
      )}
    </SettingsLayout>
  )
}

export default SettingsBranding
