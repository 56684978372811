import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { Admin } from '@/out/admin'
import { archiveAdmin, Response } from '@/thunks/admins/archive'

export default function useArchiveAdmin(onArchive: (id: string) => void) {
  const [archive, { timer }] = useAPI(archiveAdmin)

  const handleArchive = useCallback(
    async (admin: Admin): Promise<Response> => {
      timer.setId(admin.id)
      const [resp] = await archive({ id: admin.id })

      toast.warning('Admin has been archived', {
        description: admin.email,
      })

      onArchive(admin.id)

      return resp
    },
    [timer, onArchive, archive]
  )

  return [handleArchive, { timer }] as const
}
