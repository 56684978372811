import { useState } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import GoogleIcon from '@/assets/logos/google.svg?react'
import JourneySecondary from '@/assets/logos/journey-secondary.svg?react'
import MicrosoftIcon from '@/assets/logos/microsoft.svg?react'

import GoogleButton from '../sign-in/GoogleButton'
import MicrosoftButton from '../sign-in/MicrosoftButton'

const SignUp = () => {
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)

  return (
    <div className="relative flex w-full min-h-screen p-4 bg-secondary/50">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div
          className="absolute inset-0 -z-10"
          style={{
            background: 'radial-gradient(600px circle at center, transparent, rgba(0,42,78,0.05))',
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            background: `
              linear-gradient(90deg, rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
              linear-gradient(rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px
            `,
            maskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
            WebkitMaskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
          }}
        />
      </div>
      <div className="flex items-center justify-center flex-1">
        <div className="relative w-full max-w-sm p-8 space-y-4 overflow-hidden text-center bg-white border-2 border-white shadow-md rounded-xl">
          <div className="-top-20 left-1/2 absolute w-[200%] h-80 -translate-x-1/2 overflow-hidden">
            <div className="z-10 absolute inset-0 bg-gradient-to-b from-[#002A4E]/5 to-transparent" />
            <div
              className="absolute inset-0"
              style={{
                background: `
        linear-gradient(90deg, rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
        linear-gradient(rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px
      `,
                maskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
                WebkitMaskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
              }}
            />
          </div>

          <div className="relative z-30 space-y-4">
            <JourneySecondary className="w-32 h-12 mx-auto mb-4" />
            <h1 className="text-2xl font-semibold">Sign Up</h1>
            <p className="text-sm text-muted-foreground">Choose your authentication method</p>
          </div>

          <hr />

          <div
            className="relative z-30 flex items-center justify-center space-x-2 text-sm"
            onClick={() => setHasAgreedToTerms(!hasAgreedToTerms)}
          >
            <input
              type="checkbox"
              className="w-4 h-4 border-2 rounded cursor-pointer text-primary focus:ring-primary"
              checked={hasAgreedToTerms}
            />
            <label htmlFor="terms" className="cursor-pointer text-muted-foreground">
              I agree to the{' '}
              <a
                href={r.legal.dataProcessingAddendum}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                Terms of Service
              </a>
              .
            </label>
          </div>

          <div className="relative z-30 flex flex-col space-y-3">
            <GoogleButton disabled={!hasAgreedToTerms}>
              <GoogleIcon className="mr-2" />
              Google
            </GoogleButton>
            <MicrosoftButton disabled={!hasAgreedToTerms}>
              <MicrosoftIcon className="mr-2" />
              Microsoft
            </MicrosoftButton>
          </div>

          <p className="relative z-30 text-xs text-center text-muted-foreground">
            Already have an account?{' '}
            <Link to={r.root} className="text-primary hover:underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignUp
