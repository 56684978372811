import { Copy, FileKey2 } from 'lucide-react'
import { FC, useCallback, useMemo } from 'react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import useActionTimer from '@/hooks/useActionTimer'
import { AWSRegion } from '@/lib/awsRegions'

type Props = {
  region: AWSRegion
  logGroupName: string
}

const IAMPolicy: FC<Props> = ({ region, logGroupName }) => {
  const timer = useActionTimer()

  const policy = useMemo(
    () =>
      JSON.stringify(
        {
          Version: '2012-10-17',
          Statement: [
            {
              Effect: 'Allow',
              Action: ['logs:CreateLogGroup', 'logs:CreateLogStream', 'logs:PutLogEvents'],
              Resource: [
                `arn:aws:logs:${region}:*:log-group:${logGroupName}`,
                `arn:aws:logs:${region}:*:log-group:${logGroupName}:log-stream:*`,
              ],
            },
          ],
        },
        null,
        2
      ),
    [region, logGroupName]
  )

  const handleCopy = useCallback(() => {
    window.navigator.clipboard.writeText(policy)
    timer.succeeded()
  }, [policy, timer])

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="flex-1">
          <FileKey2 className="w-4 h-4 mr-2" />
          View IAM Policy
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl overflow-x-auto">
        <DialogHeader>
          <DialogTitle>IAM Policy</DialogTitle>
        </DialogHeader>
        <pre className="overflow-x-auto text-sm max-full">{policy}</pre>
        <Button variant="outline" onClick={handleCopy}>
          <Copy className="w-4 h-4 mr-2" />
          {timer.didSucceed ? 'Copied!' : 'Copy IAM Policy'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default IAMPolicy
