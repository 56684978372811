import { FC, useCallback } from 'react'

import SettingsCard from '@/components/settings-card/settings-card'
import { useAPI } from '@/hooks/useAPI'
import useObject from '@/hooks/useObject'
import TwilioIntegration from '@/pages/settings/sms-configuration/twilio/details'
import TwilioSetup from '@/pages/settings/sms-configuration/twilio/form'
import { deleteSMS } from '@/thunks/sms/delete'
import { retrieveSMSConfiguration } from '@/thunks/sms/retrieve'

import Twilio from './twilio.svg?react'

const TwilioConfiguration: FC = () => {
  const [configuration, { wasLoaded, set, clear }] = useObject(retrieveSMSConfiguration, {})
  const [remove, { timer }] = useAPI(deleteSMS)

  const handleRemove = useCallback(() => {
    remove('twilio')
    clear()
  }, [remove, clear])

  if (!wasLoaded) return null

  return (
    <SettingsCard<'sms'>
      title="Twilio"
      description="Text messages will be sent from your Twilio account."
      icon={<Twilio className="text-[#F22F46] dark:text-white w-6 h-6" />}
      configuration={configuration}
      onRemove={handleRemove}
      removeTimer={timer}
    >
      {configuration ? (
        <TwilioIntegration twilio={configuration.twilio} onRemove={clear} />
      ) : (
        <TwilioSetup onSetup={set} />
      )}
    </SettingsCard>
  )
}

export default TwilioConfiguration
