import asyncThunk from '@/lib/asyncThunk'

type Request = Record<never, never>

type Response = {
  token: string
}

export const unghost = asyncThunk<Request, Response>({
  name: 'ghost-mode/unghost',
  uri: '/ghost-mode',
  method: 'DELETE',
})
