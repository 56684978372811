import asyncThunk from '@/lib/asyncThunk'

type Request = {
  destination: 'datadog' | 'cloudwatch' | 'sumologic' | 'sftp'
}

export const deleteLogging = asyncThunk<Request, void>({
  name: 'logging/delete',
  method: 'DELETE',
  uri: '/logging',
})
