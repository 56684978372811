import { FC } from 'react'

import DataDog from '@/assets/logos/datadog.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { Logging } from '@/out/logging'
import { LoggingProps } from '@/pages/settings/logging'
import DatadogDetails from '@/pages/settings/logging/datadog/details'
import DatadogForm from '@/pages/settings/logging/datadog/form'

type Props = LoggingProps<'datadog'> & {
  configuration: Logging['datadog']
}

const DatadogIntegration: FC<Props> = ({
  configuration,
  onSetup,
  setupTimer,
  onTest,
  testTimer,
  onRemove,
  removeTimer,
}) => {
  return (
    <SettingsCard<'logging'>
      title="Datadog"
      description="Set up Datadog integration to monitor your application's performance and logs."
      icon={<DataDog className="text-[#632CA6] dark:text-white w-6 h-6" />}
      configuration={configuration}
      onRemove={() => onRemove('datadog')}
      removeTimer={removeTimer}
    >
      {configuration ? (
        <DatadogDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('datadog')} />
      ) : (
        <DatadogForm
          setupTimer={setupTimer}
          testTimer={testTimer}
          onSetup={config => onSetup('datadog', config)}
          onTest={config => onTest('datadog', config)}
        />
      )}
    </SettingsCard>
  )
}

export default DatadogIntegration
