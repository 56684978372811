import { BookText, Cog, LifeBuoy, LogOut, MessageCircleQuestion, ShieldCheck, TowerControl } from 'lucide-react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { actions } from '@/slices/auth'
import { useAppDispatch, useAuthSelector } from '@/store'

const AccountDropdown = () => {
  const { isRoot } = useAuthSelector()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    navigate(r.root)
    dispatch(actions.clearJWT())
  }, [dispatch, navigate])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          size="sm"
          variant="ghost"
          className="group text-muted-foreground hover:text-foreground data-[state=open]:text-foreground !cursor-pointer"
        >
          <Cog size={4} className="transition-transform group-hover:rotate-45 group-data-[state=open]:rotate-45" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => navigate(r.settings.root)}>
            <Cog size={16} className="mr-2" />
            Account Settings
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate(r.resources.root)}>
            <BookText size={16} className="mr-2" />
            Resources
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={() => navigate(r.support.root)}>
          <LifeBuoy size={16} className="mr-2" />
          Support Center
        </DropdownMenuItem>
        <DropdownMenuItem>
          <MessageCircleQuestion size={16} className="mr-2" />
          Live Chat
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => window.open('https://trust.journeyid.com/', '_blank')}>
          <ShieldCheck size={16} className="mr-2" />
          Trust Center
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => window.open('https://tasks.statuspage.io/', '_blank')}>
          <div className="inline-block w-3 h-3 ml-0.5 mr-2.5 bg-green-500 rounded-full cursor-pointer" />
          System status
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        {isRoot && (
          <>
            <DropdownMenuItem onClick={() => navigate(r.watchTower.root)}>
              <TowerControl size={16} className="mr-2" />
              Watch Tower
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}

        <DropdownMenuItem onClick={handleSignOut}>
          <LogOut size={16} className="mr-2" />
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AccountDropdown
