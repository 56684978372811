import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'

type Request = {
  accountId: string
}

type Response = {
  global: DayCount[]
  apps: Record<App, DayCount[]>
}

type DayCount = {
  date: string
  count: number
}

export const accountSummary = asyncThunk<Request, Response>({
  name: 'watch-tower/statistics/account',
  uri: '/watch-tower/statistics/account',
})
