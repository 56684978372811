import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'
import { AppStatus } from '@/thunks/admins/bootstrap'

type Request = {
  accountId: string
  app: App
}

type Response = {
  id: App
  status: AppStatus
}

export const setAppStatus = asyncThunk<Request, Response>({
  name: 'watch-tower/apps/status',
  uri: ({ accountId, app }) => `/watch-tower/accounts/${accountId}/apps/${app}/status`,
  method: 'PUT',
})
