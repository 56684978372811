import clsx from 'clsx'
import { FC, useState } from 'react'

import apps, { appColors } from '@/apps'
import Chart from '@/components/chart'
import { Button } from '@/components/ui/button'

import useMetrics from './useMetrics'

const WatchTowerDashboard: FC = () => {
  const { global, apps: appStats, paidAccounts } = useMetrics()
  const [viewApps, setViewApps] = useState(false)

  if (!global || !appStats || !paidAccounts) return null

  return (
    <div className="grid flex-1 w-full grid-cols-1 gap-8 px-4 py-6 mx-auto sm:grid-cols-2 max-w-7xl md:px-8">
      <Chart title="Tasks Completed" metrics={[global]} className="flex-1 w-full" />
      <Chart title="Paid Accounts" metrics={[paidAccounts]} cumulative className="flex-1 w-full" />

      {viewApps ? (
        <>
          {apps.map(app => (
            <Chart
              key={app.id}
              title={
                <span className="flex items-center gap-2">
                  <app.icon className={clsx('w-8 h-8 mr-2', appColors[app.color])} />
                  {app.title}
                </span>
              }
              metrics={[appStats[app.id]]}
              className="flex-1 w-full sm:w-full"
            />
          ))}
        </>
      ) : (
        <div className="flex items-center justify-center sm:col-span-2">
          <Button variant="ghost" className="text-muted-foreground/70" onClick={() => setViewApps(true)}>
            view apps
          </Button>
        </div>
      )}
    </div>
  )
}

export default WatchTowerDashboard
