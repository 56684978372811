import asyncThunk from '@/lib/asyncThunk'

type Request = {
  email: string
}

type Response = Record<never, never>

export const revokeInvitation = asyncThunk<Request, Response>({
  name: 'invitations/revoke',
  method: 'DELETE',
  uri: '/invitations',
})
