import { FC } from 'react'

import AWSLogo from '@/assets/logos/aws.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { Logging } from '@/out/logging'
import { LoggingProps } from '@/pages/settings/logging'
import CloudwatchDetails from '@/pages/settings/logging/cloudwatch/details'
import CloudwatchForm from '@/pages/settings/logging/cloudwatch/form'

type Props = LoggingProps<'cloudwatch'> & {
  configuration: Logging['cloudwatch']
}

const Cloudwatch: FC<Props> = ({ configuration, onSetup, setupTimer, onTest, testTimer, onRemove, removeTimer }) => {
  return (
    <SettingsCard<'logging'>
      title="Cloudwatch"
      description="Set up Cloudwatch to receive logs in log streams."
      icon={<AWSLogo className="text-[#252F3E] dark:text-white w-6 h-6" />}
      configuration={configuration}
      onRemove={() => onRemove('cloudwatch')}
      removeTimer={removeTimer}
    >
      {configuration ? (
        <CloudwatchDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('cloudwatch')} />
      ) : (
        <CloudwatchForm
          setupTimer={setupTimer}
          testTimer={testTimer}
          onSetup={config => onSetup('cloudwatch', config)}
          onTest={config => onTest('cloudwatch', config)}
        />
      )}
    </SettingsCard>
  )
}

export default Cloudwatch
