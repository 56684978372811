import asyncThunk from '@/lib/asyncThunk'
import { CustomerLookup } from '@/out/customer-lookup'

export type Request = {
  url: string
  method: 'GET' | 'POST'
  headers: { key: string; value: string }[]
  customerPath: string
  customerId: string
  titleTemplate: string
  descriptionTemplate: string
}

export const upsertCustomerLookup = asyncThunk<Request, CustomerLookup>({
  name: 'customer-lookup/upsert',
  method: 'POST',
  uri: '/customer-lookup',
})
