import { FC } from 'react'

import LoadingButton from '@/components/loading-button'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Admin } from '@/out/admin'
import useArchiveAdmin from '@/pages/settings/admin-management/useArchiveAdmin'

type Props = {
  admin: Admin
  onArchive: (id: string) => void
}

const ArchiveAdminDialog: FC<Props> = ({ admin, onArchive }) => {
  const [archive, { timer }] = useArchiveAdmin(onArchive)

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="secondary" size="sm" className="hover:bg-destructive hover:text-background">
          Remove
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your account and remove your data from our
            servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <LoadingButton timer={timer} variant="destructive" onClick={() => archive(admin)}>
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ArchiveAdminDialog
