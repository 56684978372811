import { FC } from 'react'
import { NonUndefined } from 'react-hook-form'

import LoadingButton from '@/components/loading-button'
import { ActionTimer } from '@/hooks/useActionTimer'
import { Logging } from '@/out/logging'

type Props = {
  configuration: NonUndefined<Logging['sumologic']>
  timer: ActionTimer
  onRemove: () => void
}

const SumologicDetails: FC<Props> = ({ configuration, timer, onRemove }) => {
  return (
    <div>
      <div className="pt-2 mb-6 space-y-3">
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Collector URL</span>
          <span className="block text-sm font-medium truncate">{configuration.collectorUrl}</span>
        </div>
      </div>
      <LoadingButton
        timer={timer}
        variant="secondary"
        size="sm"
        onClick={onRemove}
        className="text-xs hover:bg-destructive hover:text-background"
      >
        Disconnect
      </LoadingButton>
    </div>
  )
}

export default SumologicDetails
