import clsx from 'clsx'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import useList from '@/hooks/useList'
import { Admin } from '@/out/admin'
import { Invitation } from '@/out/invitation'
import AddAdminDialog from '@/pages/settings/admin-management/AddDialog'
import ArchiveAdminDialog from '@/pages/settings/admin-management/ArchiveDialog'
import { listAdmins, Request } from '@/thunks/admins/list'
import { listInvitations } from '@/thunks/invitations/list'

import RevokeInvitationDialog from './RevokeDialog'

function isInvitation(item: Admin | Invitation): item is Invitation {
  return 'expiresAt' in item
}

const AdminManagement: FC = () => {
  const [admins, { wasLoaded, remove }] = useList<Admin | Invitation, Request>(listAdmins, {})

  const [invitations, { wasLoaded: wasLoadedInvitations, add, remove: removeInvitation }] = useList<
    Invitation,
    Request
  >(listInvitations, {})

  const list = useMemo(() => {
    if (!wasLoaded || !wasLoadedInvitations) return
    if (!admins || !invitations) return
    return [...admins, ...invitations]
  }, [admins, invitations, wasLoaded, wasLoadedInvitations])

  return (
    <SettingsLayout>
      <Settings.Title title="Admin Management">
        <AddAdminDialog onCreate={a => add({ ...a, role: 'admin' })} />
      </Settings.Title>
      <div
        className={clsx({
          'opacity-0': !list,
          'opacity-100': !!list,
        })}
      >
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-inherit">
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead className="text-right">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(list ?? []).map(admin => (
              <TableRow key={admin.id} data-id={admin.id}>
                <TableCell>
                  {isInvitation(admin) || !admin.lastName ? (
                    <span className="italic text-muted-foreground">unknown</span>
                  ) : (
                    <>
                      {admin.firstName} {admin.lastName}
                    </>
                  )}
                </TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell className="flex justify-end">
                  {isInvitation(admin) ? (
                    <>
                      <Badge variant="outline" className="mr-4 text-black bg-yellow-400 border-0">
                        invitation expires {format(admin.expiresAt, 'MMM d, yyyy')}
                      </Badge>
                      <RevokeInvitationDialog invitation={admin} onRevoke={removeInvitation} />
                    </>
                  ) : (
                    <>
                      {admin.role === 'owner' ? (
                        <TooltipProvider>
                          <Tooltip delayDuration={100}>
                            <TooltipTrigger asChild>
                              <Button variant="secondary" size="sm" className="cursor-not-allowed">
                                Owner
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent
                              side="left"
                              className="px-2 py-1 text-xs font-medium rounded bg-foreground text-background"
                              sideOffset={4}
                            >
                              Owners can&apos;t be removed
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      ) : (
                        <ArchiveAdminDialog admin={admin} onArchive={remove} />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </SettingsLayout>
  )
}

export default AdminManagement
