import { FC } from 'react'

import Settings from '@/components/settings'
import TwilioConfiguration from '@/pages/settings/sms-configuration/twilio'
import SettingsLayout from '@/components/layout/settings-layout'

const SMSConfiguration: FC = () => {
  return (
    <SettingsLayout>
      <Settings.Title title="SMS Configuration" />
      <Settings.Description>
        We use our shared Twilio account by default. <br /> Configure your own Twilio account below to use your custom
        phone number.
      </Settings.Description>
      <TwilioConfiguration />
    </SettingsLayout>
  )
}

export default SMSConfiguration
