import asyncThunk from '@/lib/asyncThunk'

type Request = {
  email: string
}

type Response = {
  url: string
}

export const ssoAuthorize = asyncThunk<Request, Response>({
  name: 'sso/oauth/authorize',
  method: 'POST',
  uri: '/sso/oauth/authorize',
})
