import { Ghost } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import LoadingButton from '@/components/loading-button'
import SettingsNav from '@/components/settings-nav'
import useGhostMode from '@/hooks/useIsGhost'
import useObject from '@/hooks/useObject'
import { retrieveAccount } from '@/watch-tower/thunks/accounts/retrieve'

import AdminsTable from './admins-table'
import AppsTable from './apps-table'
import NavLink from './nav-item'
import WatchTowerAccountStatistics from './statistics'

type Section = 'dashboard' | 'apps' | 'administrators'

const HEADER_OFFSET = 57 + 32
const SCROLL_THRESHOLD = 100

const WatchTowerAccountsShow: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [account, { patch }] = useObject(retrieveAccount, { id: id! })
  const [, { ghost, ghostTimer }] = useGhostMode(id!)
  const [activeSection, setActiveSection] = useState<Section>('dashboard')

  const scrollToSection = (elementId: Section) => (e: React.MouseEvent) => {
    e.preventDefault()
    const element = document.getElementById(elementId)
    if (element) {
      const topOffset = element.getBoundingClientRect().top + window.scrollY - HEADER_OFFSET
      window.scrollTo({ top: topOffset, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const sections: Section[] = ['dashboard', 'administrators']

      for (const section of sections) {
        const element = document.getElementById(section)
        if (element) {
          const rect = element.getBoundingClientRect()
          if (rect.top <= HEADER_OFFSET + SCROLL_THRESHOLD && rect.bottom > HEADER_OFFSET) {
            setActiveSection(section)
            break
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Check initial position

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  if (!account) return null

  return (
    <>
      <SettingsNav>
        <LoadingButton timer={ghostTimer} variant="outline" className="mb-4" onClick={ghost}>
          <Ghost className="w-4 h-4 mr-2" />
          Ghost Mode
        </LoadingButton>
        <NavLink isActive={activeSection === 'dashboard'} onClick={scrollToSection('dashboard')}>
          Dashboard
        </NavLink>
        <NavLink isActive={activeSection === 'apps'} onClick={scrollToSection('apps')}>
          Apps
        </NavLink>
        <NavLink isActive={activeSection === 'administrators'} onClick={scrollToSection('administrators')}>
          Administrators
        </NavLink>
      </SettingsNav>
      <div className="flex-1 overflow-y-auto">
        <div className="px-4 py-8 space-y-8 md:px-8">
          <div id="dashboard">
            <WatchTowerAccountStatistics />
          </div>
          <div id="apps">
            <AppsTable
              accountId={id!}
              apps={account.apps}
              onUpdate={(app, status) => patch({ apps: { ...account.apps, [app]: { id: app, status } } })}
            />
          </div>
          <div id="administrators">
            <AdminsTable admins={account.admins} />
          </div>
        </div>
      </div>
    </>
  )
}

export default WatchTowerAccountsShow
