import { FC, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { useAPI } from '@/hooks/useAPI'
import { isErrorResponse } from '@/lib/asyncThunk'
import { actions } from '@/slices/auth'
import { useAppDispatch } from '@/store'
import { bootstrapAdmin, Response } from '@/thunks/admins/bootstrap'

type Props = {
  children: ReactNode
}

const AuthenticatedApp: FC<Props> = ({ children }) => {
  const [bootstrap, setBootstrap] = useState<Response>()

  const [doBootstrap] = useAPI(bootstrapAdmin)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    doBootstrap({})
      .then(([b]) => setBootstrap(b))
      .catch(err => {
        dispatch(actions.clearJWT())
        let uri = r.root
        if (isErrorResponse(err) && err.code === 'JWT_EXPIRED') {
          uri += '?expired=true'
        }
        navigate(uri)
      })
  }, [doBootstrap, dispatch, navigate])

  if (!bootstrap) return null

  return <>{children}</>
}

export default AuthenticatedApp
