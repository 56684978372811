import { FC, useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import { ActionTimer, idleTimer } from '@/hooks/useActionTimer'
import useObject from '@/hooks/useObject'
import { SSO, SSOProvider } from '@/out/single-sign-on'
import { retrieveSSO } from '@/thunks/single-sign-on/retrieve'
import { SSOCredentials } from '@/thunks/single-sign-on/upsert'

import AzureAD from './azure'
import Okta from './okta'
import useSSORemove from './useSSORemove'
import useSSOUpsert from './useSSOUpsert'

export type SSOProps<T extends SSOProvider> = {
  setupTimer: ActionTimer
  onSetup: (provider: T, config: NonUndefined<SSOCredentials[T]>) => Promise<SSO>
  removeTimer: ActionTimer
  onRemove: (provider: SSOProvider) => void
}

export type FormProps<T extends SSOProvider> = {
  timer: ActionTimer
  onSetup: (config: NonUndefined<SSOCredentials[T]>) => void
}

const SingleSignOn: FC = () => {
  const [configuration, { patch, wasLoaded }] = useObject(retrieveSSO, {})

  const [upsert, { timer: setupTimer }] = useSSOUpsert((provider, config) => patch({ [provider]: config }))
  const [remove, { timer: removeTimer }] = useSSORemove(provider => patch({ [provider]: undefined }))

  const props = useCallback(
    <T extends SSOProvider>(provider: T): SSOProps<T> => ({
      onSetup: upsert,
      setupTimer: setupTimer.id === provider ? setupTimer : idleTimer,
      onRemove: remove,
      removeTimer: removeTimer.id === provider ? removeTimer : idleTimer,
    }),
    [upsert, remove, setupTimer, removeTimer]
  )

  return (
    <SettingsLayout>
      <Settings.Title title="Single Sign-On" />
      <Settings.Description>
        Configure external data store providers by linking your account to third-party services.
      </Settings.Description>
      {wasLoaded && (
        <div className={'grid grid-cols-1 gap-6 lg:grid-cols-2 auto-rows-min'}>
          <AzureAD configuration={configuration?.azure} {...props('azure')} />
          <Okta configuration={configuration?.okta} {...props('okta')} />
        </div>
      )}
    </SettingsLayout>
  )
}

export default SingleSignOn
