import clsx from 'clsx'
import { Disc3Icon } from 'lucide-react'
import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button, ButtonProps } from '@/components/ui/button'
import { ActionTimer } from '@/hooks/useActionTimer'

type Props = ButtonProps & {
  timer: ActionTimer
}

const LoadingButton: FC<Props> = ({ timer, className, children, ...rest }) => {
  return (
    <Button
      disabled={timer.isLoading}
      {...rest}
      className={twMerge('relative flex items-center justify-center', className)}
    >
      <span className={clsx('flex items-center justify-center', { invisible: timer.isLoading })}>{children}</span>
      <div
        className={clsx('absolute inset-0 flex transition-opacity duration-300 items-center justify-center', {
          'opacity-0': !timer.isLoading,
          'opacity-100': timer.isLoading,
        })}
      >
        <Disc3Icon className={clsx('w-5 h-5 duration-2500', timer.isLoading && 'animate-spin')} />
      </div>
    </Button>
  )
}

export default LoadingButton
