import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { Invitation } from '@/out/invitation'
import { revokeInvitation } from '@/thunks/invitations/delete'

export default function useRevokeInvitation(onRevoke: (id: string) => void) {
  const [revoke, { timer }] = useAPI(revokeInvitation)

  const handleRevoke = useCallback(
    async (invitation: Invitation) => {
      timer.setId(invitation.id)

      await revoke({ email: invitation.email })

      toast.warning('Invitation has been revoked', {
        description: invitation.email,
      })

      onRevoke(invitation.id)
    },
    [timer, onRevoke, revoke]
  )

  return [handleRevoke, { timer }] as const
}
