import { useCallback } from 'react'
import { toast } from 'sonner'

import { useAPI } from '@/hooks/useAPI'
import { Invitation } from '@/out/invitation'
import { createInvitation, Request } from '@/thunks/invitations/create'

export default function useSendInvitation(onCreate: (invitation: Invitation) => void) {
  const [create, { timer }] = useAPI(createInvitation)

  const handleCreate = useCallback(
    async ({ email }: Request): Promise<Invitation> => {
      const [invitation] = await create({ email })

      toast.success('The invitation has been sent', {
        description: email,
      })

      onCreate(invitation)

      return invitation
    },
    [onCreate, create]
  )

  return [handleCreate, { timer }] as const
}
