import asyncThunk from '@/lib/asyncThunk'
import { Invitation } from '@/out/invitation'

export type Request = {
  email: string
}

type Response = Invitation

export const createInvitation = asyncThunk<Request, Response>({
  name: 'invitations/create',
  method: 'POST',
  uri: '/invitations',
})
