import { Disc3 } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { useAPI } from '@/hooks/useAPI'
import { useQueryParams } from '@/hooks/useQueryParams'
import { isErrorResponse } from '@/lib/asyncThunk'
import { actions } from '@/slices/auth'
import { useAppDispatch } from '@/store'
import { ssoCallback } from '@/thunks/sso/callback'

type QueryParams = {
  code: string
  state: string
}

type ErrorParams = {
  error: string
  error_description: string
}

function isSuccessful(params: Partial<QueryParams> | Partial<ErrorParams>): params is QueryParams {
  return 'code' in params && 'state' in params
}

const SSOCallback: FC = () => {
  const params = useQueryParams<QueryParams | ErrorParams>()
  const navigate = useNavigate()
  const [error, setError] = useState<string>()

  const [callback] = useAPI(ssoCallback)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!isSuccessful(params)) {
      setError(params.error_description)
      return
    }

    callback({
      code: params.code,
      state: params.state,
    })
      .then(([{ token }]) => {
        dispatch(actions.setJWT(token))
        navigate(r.root)
      })
      .catch(error => {
        if (isErrorResponse(error) && error.code === 'ADMIN_NOT_FOUND') {
          setError('Please contact your account administrator to be added as an administrator on the account.')
        } else {
          setError(error.error)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-md mx-auto border-2 shadow-lg">
        <CardHeader className="pb-6 space-y-2">
          <CardTitle className="text-xl font-bold text-center">Logging you in...</CardTitle>
          <CardDescription className="text-base text-center">Just a moment please</CardDescription>
        </CardHeader>

        <CardContent className="flex justify-center pt-6 pb-12">
          {error ? (
            <div className="flex flex-col items-center gap-4">
              <p className="text-center text-red-500">{error}</p>
              <Button className="!px-8 inline-block" onClick={() => navigate(r.root)}>
                Go back
              </Button>
            </div>
          ) : (
            <Disc3 size={48} className="animate-spin text-muted-foreground" />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default SSOCallback
