import { FC, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import Field from '@/components/field'
import LoadingButton from '@/components/loading-button'
import { SMSConfiguration } from '@/out/sms'
import useSMSUpsert from '@/pages/settings/sms-configuration/twilio/useSMSUpsert'

const schema = z
  .object({
    accountSid: z.string().min(1, { message: 'Account SID is required' }),
    authToken: z.string().min(1, { message: 'Auth Token is required' }),
    phoneNumber: z
      .string()
      .regex(/^\+[1-9]\d{1,14}$/, { message: 'Phone number must be in e164 format (with leading + and country code)' }),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = {
  onSetup: (config: SMSConfiguration) => void
}

const TwilioSetup: FC<Props> = ({ onSetup }) => {
  const [upsert, { timer }] = useSMSUpsert(onSetup)

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      accountSid: '',
      authToken: '',
      phoneNumber: '',
    },
    mode: 'onChange',
  })

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const config = await upsert({ provider: 'twilio', twilio: data })
      onSetup(config)
    },
    [upsert, onSetup]
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="w-full space-y-4">
        <Field<FormData> label="Account SID" name="accountSid" placeholder="Enter your Twilio Account SID" />
        <Field<FormData> label="Auth Token" name="authToken" placeholder="Enter your Twilio Auth Token" />
        <Field<FormData> label="Twilio Phone Number" name="phoneNumber" placeholder="+1234567890" />

        <LoadingButton type="submit" timer={timer}>
          Connect to Twilio
        </LoadingButton>
      </form>
    </FormProvider>
  )
}

export default TwilioSetup
