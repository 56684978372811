import { ReactNode } from 'react'

import { cn } from '@/lib/utils'

interface SettingsLayoutProps {
  children: ReactNode
  className?: string
}

export default function SettingsLayout({ children, className }: SettingsLayoutProps) {
  return <div className={cn('space-y-4 mx-auto max-w-5xl', className)}>{children}</div>
}
