import { Check, LucideIcon } from 'lucide-react'
import { FC } from 'react'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { AppConfig, isFreeTrial } from '@/thunks/admins/bootstrap'

import { PlanBadge } from '../global/plan-badge'
import CreditUsageBar from './credit-usage-bar'

interface BillingPlan {
  icon: LucideIcon
  title: string
  description: string
  config: AppConfig
  topFeatures?: string[]
  onClick: () => void
}

const BillingCard: FC<BillingPlan> = ({ icon: Icon, title, description, config, topFeatures, onClick }) => {
  return (
    <Card className="flex flex-col w-[320px]">
      <CardHeader className="flex flex-col pb-2">
        <div className="flex items-center justify-between w-full">
          <Icon className="w-8 h-8" />
          <PlanBadge plan={config.status} className="whitespace-nowrap" />
        </div>
        <CardTitle className="pt-3">{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <Separator />
      <CardContent>
        {config.status !== 'inactive' && (
          <>
            {config.status !== 'free-trial' ? (
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <p className="text-sm font-semibold">Monthly cost</p>
                  <p className="text-sm text-muted-foreground">$80.99</p>
                </div>
                <div>
                  <p className="text-sm font-semibold">Overage Cost</p>
                  <p className="text-sm text-muted-foreground">$0.30/item</p>
                </div>
                <div>
                  <p className="text-sm font-semibold">Total Cost</p>
                  <p className="text-sm text-muted-foreground">$120.99</p>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <p className="mb-2 text-sm font-semibold">Upgrade to unlock:</p>
                <ul className="space-y-1">
                  {topFeatures?.map((feature, index) => (
                    <li key={index} className="flex items-center text-sm">
                      <Check className="w-4 h-4 mr-2 text-green-500" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
        {config.status === 'inactive' && (
          <div className="mt-4">
            <p className="mb-2 text-sm font-semibold">Start your free trial to get:</p>
            <ul className="space-y-1">
              {topFeatures?.map((feature, index) => (
                <li key={index} className="flex items-center text-sm">
                  <Check className="w-4 h-4 mr-2 text-green-500" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        )}
      </CardContent>
      <CardFooter className="mt-auto">
        {isFreeTrial(config) ? (
          <div className="flex items-end justify-between w-full">
            <CreditUsageBar config={config} />
            <Button onClick={onClick}>Upgrade</Button>
          </div>
        ) : config.status === 'inactive' ? (
          <div className="flex flex-col w-full text-center">
            <Button variant="outline" className="w-full" onClick={onClick}>
              Start a free trial
            </Button>
          </div>
        ) : (
          <Button className="w-full" variant="outline" onClick={onClick}>
            Manage Subscription
          </Button>
        )}
      </CardFooter>
    </Card>
  )
}

export default BillingCard
